/* eslint-disable no-param-reassign */

(function() {
  angular.module('app.services').factory('languageInterceptor', [
    '$location',
    '$q',
    '$rootScope',
    function($location, $q, $rootScope) {
      // /////////////////////////////////////////////////////////////////////////
      function getCookie(name) {
        var re = new RegExp(name + '=([^;]+)');
        var value = re.exec(document.cookie);
        return value !== null ? unescape(value[1]) : null;
      }
      // /////////////////////////////////////////////////////////////////////////
      const translatedLanguages = ['en', 'fr', 'es'];
      // /////////////////////////////////////////////////////////////////////////
      let language = getCookie('l');
      if (!language || translatedLanguages.indexOf(language) === -1) {
        let systemLanguage = null;
        if (navigator && navigator.languages) {
          for (let i = 0; i < navigator.languages.length; i += 1) {
            if (translatedLanguages.indexOf(navigator.languages[i]) !== -1) {
              systemLanguage = navigator.languages[i];
              break;
            }
          }
        }
        if (systemLanguage) {
          language = systemLanguage;
        } else {
          language = 'en';
        }
        if (language !== 'en') {
          document.cookie = 'l=' + language + ';path=/';
          // window.location.reload(true);
        }
      } else if (language !== 'en') {
        document.cookie = 'l=' + language + ';path=/';
      }
      window.moment.locale(language);
      // /////////////////////////////////////////////////////////////////////////
      return {
        // TODO: Resolve consistent return
        responseError: function(rejection) {
          if (rejection.status === 404) {
            $location.path('/');
            return $q.reject(rejection);
          }
        },

        request: function(config) {
          if (config.url.indexOf($rootScope.api_url) !== -1) {
            if (config.url.indexOf('?') === -1) {
              config.url += '?';
            }
            if (
              config.url[config.url.length - 1] !== '&' &&
              config.url[config.url.length - 1] !== '?'
            ) {
              config.url += '&';
            }
            config.url += 'l=' + language;
          }
          return config;
        },
      };
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
