/* tslint:disable:only-arrow-functions */

(() => {
  // @ts-ignore
  angular.module('app').config([
    'uiGmapGoogleMapApiProvider',
    function(uiGmapGoogleMapApiProvider) {
      uiGmapGoogleMapApiProvider.configure({
        key: window.appSettings.google.maps.api.key,
        v: '3', // defaults to latest 3.X but throws a warning in the console
        libraries: 'weather,geometry,visualization,places',
      });
    },
  ]);
})();
