/* eslint-disable func-names */

(function() {
  angular.module('app', [
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ui.router',
    'ui.bootstrap',
    'uiGmapgoogle-maps',
    'angular.filter',
    'ncy-angular-breadcrumb',
    'ngTagsInput',
    'angular-storage',
    'auth0.auth0',
    'app.routes',
    'app.services',
    'app.filters',
    'app.directives',
  ]);
})();
