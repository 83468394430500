// Dependencies (third-party code) to be imported into `vendor-bundle`

/* tslint:disable:interface-name */
/* tslint:disable:no-var-requires */

// The allowed properties of `window` are typed in `globals.d.ts`
window.he = require('he'); // 1.1.1
window.jQuery = require('jquery'); // 3.1.1
window._ = require('lodash'); // 4.17.10
window.moment = require('moment'); // 2.22.2

require('bootstrap'); // 3.3.7
require('sweetalert'); // 1.1.3

require('angular'); // 1.5.6
require('angular-animate'); // 1.5.6
require('angular-cookies'); // 1.5.6
require('angular-resource'); // 1.5.6
require('angular-sanitize'); // 1.5.6
require('@uirouter/angularjs'); // 0.4.2
require('angular-ui-bootstrap'); // 1.1.2
require('angular-filter'); // 0.5.17
require('angular-breadcrumb'); // 0.5.0
require('angular-simple-logger'); // 0.1.7
require('angular-google-maps'); // 2.4.1
require('ng-tags-input'); // 3.2.0
require('angular-storage'); // 0.0.15
// require('angular-touch');
// require('angular-css');

require('auth0-js'); // 9.7.2
require('angular-auth0/src'); // 3.0.0
