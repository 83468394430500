/* eslint-disable no-param-reassign */

(function() {
  angular.module('app.directives').directive('progressionbar', [
    '$uibModal',
    function($uibModal) {
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/progression_bar.html',
        scope: {
          progression: '@',
          hideHeader: '@',
        },
        link: function(scope, element, attr) {
          scope.openModal = function() {
            var modalInstance = $uibModal.open({
              animation: true,
              templateUrl: 'progressionInfoModal.tpl.html',
              controller: [
                '$scope',
                '$uibModalInstance',
                function($scope, $uibModalInstance) {
                  $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                  };
                },
              ],
              size: 'md',
              backdrop: true,
            });
            modalInstance.result.then(
              function() {
                console.log('Finished');
              },
              function() {
                console.log('Modal dismissed at : ' + new Date());
              },
            );
          };

          attr.$observe('progression', function(value) {
            applyProgressionClass(value);
          });

          const applyProgressionClass = function(progressionValue) {
            switch (progressionValue) {
              case 'Established':
                element.find('.progression-bar .circle:nth-of-type(4)').addClass('done');
                element.find('.progression-bar .circle:nth-of-type(3)').addClass('done');
                element.find('.progression-bar .circle:nth-of-type(2)').addClass('done');
                element.find('.progression-bar .circle:nth-of-type(1)').addClass('done');
                element.find('.progression-bar .bar:nth-of-type(3)').addClass('done');
                element.find('.progression-bar .bar:nth-of-type(2)').addClass('done');
                element.find('.progression-bar .bar:nth-of-type(1)').addClass('done');
                // element.find('.btn-progression').html('Established');
                // console.log('progression value established');
                break;
              case 'Adopted':
                element.find('.progression-bar .circle:nth-of-type(3)').addClass('done');
                element.find('.progression-bar .circle:nth-of-type(2)').addClass('done');
                element.find('.progression-bar .circle:nth-of-type(1)').addClass('done');
                element.find('.progression-bar .bar:nth-of-type(2)').addClass('done');
                element.find('.progression-bar .bar:nth-of-type(1)').addClass('done');
                // element.find('.btn-progression').html('Adopted');
                // console.log('progression value adopted');
                break;
              case 'Tested':
                element.find('.progression-bar .circle:nth-of-type(2)').addClass('done');
                element.find('.progression-bar .circle:nth-of-type(1)').addClass('done');
                element.find('.progression-bar .bar:nth-of-type(1)').addClass('done');
                // element.find('.btn-progression').html('Tested');
                // console.log('progression value tested');
                break;
              case 'Emerging':
                element.find('.progression-bar .circle:nth-of-type(1)').addClass('done');
                element.find('.progression-bar .bar:nth-of-type(1)').addClass('done');
                // element.find('.btn-progression').html('Emerging');
                // console.log('progression value emerging');
                break;
              default:
              // console.log("Product progression doesn't match designated values.");
            }
          };
        },
      };
    },
  ]);
})();
