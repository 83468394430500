/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */

(() => {
  angular.module('app.services').service('GoogleMapUtil', [
    '$http',
    '$rootScope',
    'store',
    'uiGmapGoogleMapApi',
    function($http, $rootScope, store, uiGmapGoogleMapApi) {
      // /////////////////////////////////////////////////////////////////////////
      // # Projects //////////////////////////////////////////////////////////////
      // /////////////////////////////////////////////////////////////////////////
      var self = this;

      let hwtsProjects = store.get('bsfkbProjectsV3');
      const timestamp = window._.get(hwtsProjects, 'timestamp');
      let loaded = false;
      if (!timestamp || window.moment().isSameOrAfter(timestamp)) {
        hwtsProjects = {
          timestamp: window
            .moment()
            .add(7, 'days')
            .toDate(),
          projects: [
            {
              id: 10000,
              latitude: -179,
              longitude: 1,
            },
          ],
        };
        if (!loaded) {
          $http.get($rootScope.api_url + '/public/projects').then((response) => {
            if (!response || !response.data) {
              return;
            }
            hwtsProjects.projects = self.processProjects(response.data);
            store.set('bsfkbProjectsV3', hwtsProjects);
            loaded = true;
          });
        }
      } else {
        loaded = true;
      }
      // /////////////////////////////////////////////////////////////////////////
      this.getLat = (locations) => {
        return window._.get(
          locations,
          '[0].geolocation.coordinates[1]',
          window._.get(locations, '[0].geolocation.latitude'),
        );
      };
      // /////////////////////////////////////////////////////////////////////////
      this.getLng = (locations) => {
        return window._.get(
          locations,
          '[0].geolocation.coordinates[0]',
          window._.get(locations, '[0].geolocation.longitude'),
        );
      }; // /////////////////////////////////////////////////////////////////////////
      this.processProjects = function(projects) {
        var selfProcess = this;
        projects.forEach((project, index) => {
          projects[index] = selfProcess.processProject(project);
        });
        return projects;
      };
      // /////////////////////////////////////////////////////////////////////////
      this.processProject = function(project) {
        project.organization = window._.get(project, 'organizations[0].name', null);
        project.latitude = this.getLat(project.locations);
        project.longitude = this.getLng(project.locations);
        project.countries = window._.get(project, 'locations[0].countryName', null);
        return project;
      };
      // /////////////////////////////////////////////////////////////////////////
      this.getProjects = (cb) => {
        const waitTimer = setInterval(function() {
          if (loaded) {
            clearInterval(waitTimer);
            cb(hwtsProjects.projects);
          }
        }, 500);
      };
      // /////////////////////////////////////////////////////////////////////////
      this.getProject = function(projectID, cb) {
        const getProjectSelf = this;
        $http.get($rootScope.hwts_api_url + '/public/project/' + projectID).then(
          (response) => {
            if (response && response.data) {
              cb(getProjectSelf.processProject(response.data));
            } else {
              cb(null);
            }
          },
          (_data, _status) => {
            cb(null);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////////
      // # Map ///////////////////////////////////////////////////////////////////
      // /////////////////////////////////////////////////////////////////////////
      const map = {
        center: {
          latitude: 1.51,
          longitude: -1.76,
        },
        zoom: 2,
        control: {},
        window: {
          show: false,
          closeClick() {
            this.show = false;
          },
        },
      };
      // /////////////////////////////////////////////////////////////////////////
      const mapOptions = {
        scrollwheel: false,
        panControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        minZoom: 2,
        maxZoom: 15,
        zoomControl: true,
      };

      let typeOfMarker = 'cluster';

      const markerOptions = {
        icon: '../../images/marker_icon.png',
      };

      const spiderfierOptions = {
        keepSpiderfied: true,
        legWeight: 1,
        nearbyDistance: 30,
      };

      const clusterOptions = {
        zoomOnClick: true,
        maxZoom: 6,
        gridSize: 40,
        styles: [
          {
            textColor: '#333333',
            url: '../../images/cluster_icon.png',
            height: 50,
            width: 50,
          },
        ],
      };

      let typeOptions = clusterOptions;
      // /////////////////////////////////////////////////////////////////////////
      const mapEvents = {
        zoom_changed(thisMap) {
          var zoomLevel = thisMap.getZoom();
          if (zoomLevel >= 6) {
            typeOfMarker = 'spider';
            typeOptions = spiderfierOptions;
          } else {
            typeOfMarker = 'cluster';
            typeOptions = clusterOptions;
          }
        },
      };

      const markerEvents = {
        click: function(_marker, _eventName, model) {
          map.window.model = model;
          map.window.show = true;
        },
      };
      // /////////////////////////////////////////////////////////////////////////
      uiGmapGoogleMapApi.then(() => {
        mapOptions.zoomControlOptions = {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        };

        // http://angular-ui.github.io/angular-google-maps/#!/api/GoogleMapApi
        // uiGmapGoogleMapApi is a promise.
        // The "then" callback function provides the google.maps object
        // Some of the directives require at least something to be defined originally, e.g. $scope.markers = []
      });
      // /////////////////////////////////////////////////////////////////////////
      const mapSettings = {
        map: map,
        mapOptions: mapOptions,
        typeOfMarker: typeOfMarker,
        markerOptions: markerOptions,
        spiderfierOptions: spiderfierOptions,
        clusterOptions: clusterOptions,
        typeOptions: typeOptions,
        mapEvents: mapEvents,
        markerEvents: markerEvents,
      };
      this.getMapSettings = () => {
        return window._.clone(mapSettings);
      };
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
