/* eslint-disable no-param-reassign */
(function() {
  angular.module('app.directives').directive('starRating', function() {
    // //////////////////////////////////////////////////////////////////////////
    return {
      restrict: 'E',
      templateUrl: '/scripts/directives/star_rating.html',
      scope: {
        rating: '=',
        limit: '=',
      },
      link: function(scope) {
        scope.starRating = [];
        for (let i = 0; i < scope.limit; i += 1) {
          scope.starRating.push({
            filled: i < scope.rating,
          });
        }
      },
    };
  });
})();
