/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { toNgPost } from '@src/client/utilities/toNgPost';
import * as angular from 'angular';

angular //
  .module('app')
  .controller('UpdateDetailCtrl', [
    '$rootScope',
    '$scope',
    '$stateParams',
    'StoryblokUtils',
    'MetaTagsUtil',
    ($rootScope, $scope, $stateParams, StoryblokUtils, MetaTagsUtil) => {
      // //////////////////////////////////////////////////////////////////////////
      $scope.updatePost = {};
      $rootScope.updatePostTitle = '';
      $scope.updatePost.loading = true;
      // //////////////////////////////////////////////////////////////////////////
      StoryblokUtils.getBlogBySlug($stateParams.postSlug, (data) => {
        $scope.updatePost = toNgPost(data.story);

        $rootScope.updatePostTitle = $scope.updatePost.title;
        $scope.updatePost.loading = false;

        const postDescription = $scope.updatePost.excerpt || $scope.updatePost.title;
        const socialImage =
          $scope.updatePost.featured_image.source ||
          `${$rootScope.image_serve_url}images/social/bsf_social_types_updates.jpg`;

        MetaTagsUtil.SetSocialMetaTags({
          ogTitle: $rootScope.updatePostTitle,
          ogDescription: postDescription,
          ogUrl: `/updates/${$scope.updatePost.slug}`,
          ogAltImage: socialImage,
        });
      });
      // //////////////////////////////////////////////////////////////////////////
    },
  ]);
