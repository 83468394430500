/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */

(function() {
  angular.module('app.directives').directive('listItem', [
    'bsfUiUtil',
    function(bsfUiUtil) {
      // /////////////////////////////////////////////////////////////////////////
      function getPdfUri(item) {
        let uri = null;
        if (item.files && item.files.length > 0) {
          const idx = window._.findIndex(item.files, { type: 'application/pdf' });
          if (idx > -1 && item.files[idx].uri) {
            uri = item.files[idx].uri;
          }
        }
        return uri;
      }
      // /////////////////////////////////////////////////////////////////////////
      function setContentUrl(item, type) {
        var slugConversion = {
          casestudy: 'case-study',
          sand_producer: 'producer',
          mold_producer: 'producer',
          project: 'projects',
          update: 'updates',
        };

        var urlPieces = [slugConversion[type] || type, item.slugId, item.slug];

        if (urlPieces[0] === 'updates') {
          if (item.type === 'post') {
            urlPieces = ['updates', item.slug];
          } else {
            // updates contain blog posts and documents. ex. document, policy_regulation, manual, manufacturer_sheet...
            urlPieces[0] = 'document';
          }
        }

        return '/' + urlPieces.join('/');
      }
      // /////////////////////////////////////////////////////////////////////////
      function normalizeData(item, type) {
        var normalizedItem = window._.cloneDeep(item);
        if (type === 'casestudy') {
          normalizedItem.pdfUri = getPdfUri(item);
        }
        if (type === 'document') {
          normalizedItem.pdfUri = getPdfUri(item);
          normalizedItem.url = item.websites && item.websites[0] ? item.websites[0].url : null;
        }
        if (type === 'update') {
          if (item.title) {
            normalizedItem.title = item.title;
          }
        }
        if (type === 'document' || type === 'update') {
          normalizedItem.description = normalizedItem.description
            ? String(normalizedItem.description).replace(/<[^>]+>/gm, '')
            : '';
        }
        if (type === 'mold_producer' || type === 'sand_producer') {
          if (item.locations[0].city) {
            normalizedItem.city = item.locations[0].city;
          }
        }
        return normalizedItem;
      }
      // /////////////////////////////////////////////////////////////////////////
      function setIconClass(type, docType) {
        var css = 'fa fa-file-o';
        var classes = {
          'case-study': 'fa fa-pencil',
          research: 'fa fa-graduation-cap',
          experience: 'fa fa-quote-left',
          faq: 'fa fa-question-circle',
          project: 'fa fa-map-marker',
          mold_producer: 'fa fa-wrench',
          sand_producer: 'fa fa-wrench',
        };
        var docClasses = {
          policy_regulation: 'fa fa-globe',
          website: 'fa fa-link',
        };
        if (type === 'document' || type === 'update') {
          css = docClasses[docType] ? docClasses[docType] : css;
        } else {
          css = classes[type] ? classes[type] : css;
        }
        return css;
      }
      // /////////////////////////////////////////////////////////////////////////
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/list_item.html',
        scope: {
          item: '=',
          type: '=',
        },
        link: function(scope) {
          scope.descLength = 250;
          scope.showPdfDownload = false;

          scope.normalizedItem = normalizeData(scope.item, scope.type);
          scope.itemUrl = setContentUrl(scope.item, scope.type);

          scope.setIconClass = setIconClass;
          scope.getPublicPdfDownload = bsfUiUtil.getPublicPdfDownload;
        },
      };
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
