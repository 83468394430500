/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app').controller('CountryDetailCtrl', [
    '$element',
    '$http',
    '$location',
    '$rootScope',
    '$scope',
    '$state',
    '$uiViewScroll',
    'bsfData',
    'GoogleMapUtil',
    'MetaTagsUtil',
    function(
      $element,
      $http,
      $location,
      $rootScope,
      $scope,
      $state,
      $uiViewScroll,
      bsfData,
      GoogleMapUtil,
      MetaTagsUtil,
    ) {
      // @TODO Don't send file information for research
      // //////////////////////////////////////////////////////////////////////
      var countryId = $state.params.slugId ? $state.params.slugId : null;
      var countryUrl = $location.$$url;

      if (!countryId) {
        $state.go('country');
      }
      // //////////////////////////////////////////////////////////////////////
      $scope.countryDetail = {};
      // //////////////////////////////////////////////////////////////////////
      $scope.countryDetail.data = {};
      $scope.countryDetail.sectionLength = 4;
      $scope.countryDetail.hasContent = true;
      $scope.countryDetail.sections = {
        sand_producer: {
          id: 'sand_producer',
          show: false,
          title: 'Sand Producers',
          items: [],
        },
        mold_producer: {
          id: 'mold_producer',
          show: false,
          title: 'Mold Producers',
          items: [],
        },
        document: {
          id: 'document',
          show: false,
          title: 'Documents',
          items: [],
        },
        experience: {
          id: 'experience',
          show: false,
          title: 'Experiences',
          items: [],
        },
        research: {
          id: 'research',
          show: false,
          title: 'Research',
          items: [],
        },
        'case-study': {
          id: 'casestudy',
          show: false,
          title: 'Case Studies',
          items: [],
        },
        project: {
          id: 'project',
          show: false,
          title: 'Projects',
          items: [],
        },
      };
      // //////////////////////////////////////////////////////////////////////
      function getCountryData(id, cb) {
        bsfData.getCountryDetails(id, function(data) {
          if (data) {
            $rootScope.countryNameLabel = data.name;
            $scope.countryDetail.data = data;
            cb(data);

            MetaTagsUtil.SetSocialMetaTags({
              ogTitle: $rootScope.countryNameLabel,
              ogDescription: 'Resources in ' + $rootScope.countryNameLabel,
              ogUrl: countryUrl,
              ogImage: 'images/flags/' + $scope.countryDetail.data.flag,
              ogImageWidth: ' ',
              ogImageHeight: ' ',
            });
          }
        });
      }
      // //////////////////////////////////////////////////////////////////////
      function getCountryContent(countryCode, contentType, cb) {
        function showIfEmpty() {
          var showEmptySections = ['experience', 'research', 'document'];
          if (showEmptySections.indexOf(contentType) !== -1) {
            $scope.countryDetail.sections[contentType].show = true;
          }
          if (contentType === 'bsfproducer') {
            var bsfProducerSections = ['mold_producer', 'sand_producer'];
            bsfProducerSections.forEach(function(section) {
              $scope.countryDetail.sections[section].show = true;
            });
          }
        }
        // //////////////////////////////////////////////////////////////////////
        function hasContent() {
          var sections = $scope.countryDetail.sections;
          var sectionsWithContent = [];
          for (var key in sections) {
            if (sections[key].items.length > 0) {
              sectionsWithContent.push(sections[key].id);
            }
          }
          if (sectionsWithContent.length > 0) {
            return true;
          }
          return false;
        }
        // //////////////////////////////////////////////////////////////////////
        bsfData.getCountryAssociations(countryCode, contentType, function(data) {
          if (data && data.length > 0) {
            if (contentType === 'project') {
              var projects = GoogleMapUtil.processProjects(data);
              var latitudes = [];
              var longitudes = [];
              projects.forEach(function(project) {
                if (project.latitude) {
                  latitudes.push(project.latitude);
                }
                if (project.longitude) {
                  longitudes.push(project.longitude);
                }
              });
              $scope.countryDetail.data.center = {
                latitude: window._.mean(latitudes),
                longitude: window._.mean(longitudes),
              };
              $scope.countryDetail.sections[contentType].items = projects;
              $scope.countryDetail.sections[contentType].show = true;
            } else if (contentType === 'document') {
              var docs = [];
              data.forEach(function(doc) {
                if (doc.type === 'policy_regulation') {
                  docs.push(doc);
                } else {
                  docs.push(doc);
                }
              });
              if (docs.length > 0) {
                $scope.countryDetail.sections.document.items = docs;
                $scope.countryDetail.sections.document.show = true;
              }
            } else if (contentType === 'bsfproducer') {
              var sandProducers = [];
              var moldProducers = [];
              data.forEach(function(producer) {
                if (producer.producerType === 'Mold') {
                  moldProducers.push(producer);
                } else if (producer.producerType === 'Sand') {
                  sandProducers.push(producer);
                }
              });
              if (moldProducers.length > 0) {
                $scope.countryDetail.sections.mold_producer.items = moldProducers;
                $scope.countryDetail.sections.mold_producer.show = true;
              } else {
                showIfEmpty();
              }
              if (sandProducers.length > 0) {
                $scope.countryDetail.sections.sand_producer.items = sandProducers;
                $scope.countryDetail.sections.sand_producer.show = true;
              } else {
                showIfEmpty();
              }
            } else {
              $scope.countryDetail.sections[contentType].items = data;
              $scope.countryDetail.sections[contentType].show = true;
            }
          } else {
            showIfEmpty();
          }
          $scope.countryDetail.hasContent = hasContent();
        });
      }
      // //////////////////////////////////////////////////////////////////////
      $scope.countryDetail.loadingContent = true;

      getCountryData(countryId, function() {
        getCountryContent($scope.countryDetail.data.code, 'document');
        getCountryContent($scope.countryDetail.data.code, 'project');
        getCountryContent($scope.countryDetail.data.code, 'case-study');
        getCountryContent($scope.countryDetail.data.code, 'research');
        getCountryContent($scope.countryDetail.data.code, 'experience');
        getCountryContent($scope.countryDetail.data.code, 'bsfproducer');
        $scope.countryDetail.loadingContent = false;
      });
      // //////////////////////////////////////////////////////////////////////
      $scope.countryDetail.scrollToSection = function(elementId) {
        $uiViewScroll($element.find('#' + elementId));
      };
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
