import { CustomSchema } from '@src/client/scripts/services/sbCustomRichtextSchema';
import { NgPost } from '@src/client/types/NgPost';
import { SbPost } from '@src/client/types/SbPost';

import RichTextResolver from 'storyblok-js-client/dist/rich-text-resolver.es';

const resolver = new RichTextResolver(CustomSchema);

export const toNgPost = (story: SbPost): NgPost => ({
  featured_image: {
    source: story.content.featured_image.filename || null,
  },
  slug: story.slug,
  title: story.content.title,
  date: story.first_published_at,
  excerpt: story.content.intro,
  tags: story.tag_list.slice().sort(),
  content: resolver.render(story.content.body),
});
