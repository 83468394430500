(function() {
  angular.module('app.directives').directive('viewAllButton', function() {
    return {
      restrict: 'E',
      templateUrl: '/scripts/directives/viewall_button.html',
      scope: {
        url: '@',
      },
    };
  });
})();
