/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import * as moment from 'moment';

angular //
  .module('app')
  .controller('QuestionnaireController', [
    '$scope',
    '$window',
    '$timeout',
    'authService',
    'store',
    function($scope, $window, $timeout, authService, store) {
      const daysSinceLastQualification = -1;
      const secondsToShow = 2;

      $scope.name = '';
      $scope.organization = '';
      $scope.roleInOrganization = '';

      $scope.showQ1 = false;
      $scope.showQ2 = false;

      function showQuestionnaire() {
        $scope.showQ1 = true;
      }

      function overdueForQualification(lastAttempt: number) {
        const threshold = moment()
          .utc()
          .subtract(daysSinceLastQualification, 'days');
        const previousAttempt = moment(lastAttempt).utc();

        return previousAttempt.isBefore(threshold);
      }

      authService.subscribeToAuthentication((data) => {
        if (!data.isSignedIn) {
          return;
        }

        $scope.name = data.profile.name;
        $scope.organization = data.profile.intercom_org || '';
        $scope.organizationRole = data.profile.intercom_role || '';

        const {
          intercom_ImplementingWashProject: isImplementing,
          intercom_LastAskedImplWash: lastAsked,
        } = data.profile;

        if (isImplementing === '' && overdueForQualification(lastAsked)) {
          $timeout(showQuestionnaire, secondsToShow * 1000);
        }
      });

      $scope.closeFirstQuestionnaire = () => {
        const nowUnix = moment().unix();

        $window.Intercom('update', { LastAskedImplWash: nowUnix });

        const profile = store.get('profile');
        profile.intercom_LastAskedImplWash = nowUnix;
        store.set('profile', profile);

        $scope.showQ1 = false;
      };

      $scope.closeSecondQuestionnaire = () => {
        $scope.showQ2 = false;
      };

      $scope.washImplementationAnswer = (answer: string) => {
        // Protect against hostile input
        if (answer !== 'Yes' && answer !== 'No' && answer !== 'In the future') {
          return;
        }

        $window.Intercom('update', { ImplementingWashProject: answer });
        const profile = store.get('profile');
        profile.intercom_ImplementingWashProject = answer;
        store.set('profile', profile);

        $scope.showQ1 = false;

        if (answer !== 'No') {
          $scope.showQ2 = true;
        }
      };

      $scope.submitSecondQuestionnaire = () => {
        $window.Intercom('update', {
          name: $scope.name,
          organization: $scope.organization,
          role_In_Organization: $scope.roleInOrganization,
        });

        const profile = store.get('profile');
        profile.name = $scope.name;
        profile.intercom_org = $scope.organization;
        profile.intercom_role = $scope.roleInOrganization;
        store.set('profile', profile);

        $scope.showQ2 = false;
      };
    },
  ]);
