/* eslint-disable */

(function() {
  angular.module('app').controller('SearchCtrl', [
    '$location',
    '$rootScope',
    '$scope',
    '$state',
    'bsfData',
    'bsfUiUtil',
    'MetaTagsUtil',
    function($location, $rootScope, $scope, $state, bsfData, bsfUiUtil, MetaTagsUtil) {
      ////////////////////////////////////////////////////////////////////////
      $scope.themeDetails = {
        faq: {
          type: 'faq',
          title: bsfUiUtil.contentTypeLabelMap['faq'],
          description: '',
          slug: 'faq',
        },
        research: {
          type: 'research',
          title: bsfUiUtil.contentTypeLabelMap['research'],
          description: '',
          slug: 'research',
        },
        casestudies: {
          type: 'casestudy',
          title: bsfUiUtil.contentTypeLabelMap['casestudies'],
          description: '',
          slug: 'case-studies',
        },
      };
      // filters from URL
      $scope.filters = {
        categories: [],
        countries: [],
        resourceTypes: [],
        themes: [],
        authors: [],
        publicationType: [],
        publisher: [],
        //'bsfModels':[] @TODO: will add this filter when there is data
      };
      $scope.searchError = false;
      $scope.loadingResults = false;
      $scope.currentState = $state.current.name || 'search';
      $scope.currentTheme = $scope.themeDetails[$scope.currentState];
      $scope.filterLimit = 5;
      $scope.pageSize = 10;
      $scope.categoriesLimit = $scope.filterLimit;
      $scope.countriesLimit = $scope.filterLimit;
      $scope.authorsLimit = $scope.filterLimit;
      $scope.publicationTypeLimit = $scope.filterLimit;
      $scope.publisherLimit = $scope.filterLimit;
      $scope.organizationsLimit = $scope.filterLimit;
      ////////////////////////////////////////////////////////////////////////
      $scope.searchFilters = {}; // side bar search filters
      $scope.searchPageInfo = {};
      $scope.searchPageInfo.currentUrl = $scope.currentTheme ? $scope.currentTheme.slug : 'search';
      $scope.searchPageInfo.searchTitle = $scope.currentTheme
        ? $scope.currentTheme.title
        : 'Search';
      if ($scope.currentTheme) {
        $scope.placeholderText = 'Search ' + $scope.searchPageInfo.searchTitle + '...';
      } else {
        $scope.placeholderText = 'Search...';
      }
      ////////////////////////////////////////////////////////////////////////
      function init() {
        $scope.page = Number($state.params.page) || 1;
        $scope.searchQuery = $state.params.query || '';
        $scope.filters.categories = $state.params.categories
          ? $state.params.categories.split(';')
          : [];
        $scope.filters.countries = $state.params.countries
          ? $state.params.countries.split(';')
          : [];
        $scope.filters.authors = $state.params.authors ? $state.params.authors.split(';') : [];
        $scope.filters.publicationType = $state.params.publicationType
          ? $state.params.publicationType.split(';')
          : [];
        $scope.filters.publisher = $state.params.publisher
          ? $state.params.publisher.split(';')
          : [];

        if ($scope.themeDetails.hasOwnProperty($scope.currentState)) {
          $scope.filters.resourceTypes = [$scope.themeDetails[$scope.currentState].type];
        } else {
          $scope.filters.resourceTypes = $state.params.resourceTypes
            ? $state.params.resourceTypes.split(';')
            : [];
        }

        $scope.filters.themes = $state.params.themes ? $state.params.themes.split(';') : [];
        $scope.filters.publicationYearFrom = Number($state.params.publicationYearFrom);
        $scope.filters.publicationYearTo = Number($state.params.publicationYearTo);
        $scope.publicationYearFromSearched = Number($state.params.publicationYearFrom);
        $scope.publicationYearToSearched = Number($state.params.publicationYearTo);

        bsfData.getSearchFilters(function(data) {
          if (data) {
            $scope.searchFilters.resourceType = data.resourceType;
            for (var i = $scope.searchFilters.resourceType.length - 1; i >= 0; i--) {
              if ($scope.searchFilters.resourceType[i] === 'prodtech') {
                $scope.searchFilters.resourceType.splice(i, 1);
              }
            }
          }
        });

        $scope.search();
      }
      ////////////////////////////////////////////////////////////////////////
      $scope.search = function() {
        $scope.loadingResults = true;
        var url =
          window.appSettings.urls.hwtsSiteApiBaseUrl + '/public/search?include_updates=true&filter[technologies]=Biosand&';

        $scope.searchQuery = $scope.searchQuery || '';
        url += 'q=' + $scope.searchQuery + '&';

        if ($scope.currentState === 'casestudies') {
          $scope.filters.organizations = $state.params.organizations
            ? $state.params.organizations.split(';')
            : [];
          url += 'filter[organizations]=' + $scope.filters.organizations.join(';') + '&';
        }

        if ($scope.currentState === 'research') {
          if (Array.isArray($scope.filters.publicationYearFrom)) {
            $scope.filters.publicationYearFrom = null;
          }
          $scope.filters.publicationYearFrom = $scope.filters.publicationYearFrom || 1900;
          url += 'filter[publicationYearFrom]=' + $scope.filters.publicationYearFrom + '&';
          $scope.publicationYearFromSearched = $scope.filters.publicationYearFrom;

          if (Array.isArray($scope.filters.publicationYearTo)) {
            $scope.filters.publicationYearTo = null;
          }
          $scope.filters.publicationYearTo =
            $scope.filters.publicationYearTo || new Date().getFullYear();
          url += 'filter[publicationYearTo]=' + $scope.filters.publicationYearTo + '&';
          $scope.publicationYearToSearched = $scope.filters.publicationYearTo;

          if ($scope.filters.authors.length > 0) {
            url += 'filter[authors]=' + $scope.filters.authors.join(';') + '&';
          }

          if ($scope.filters.publicationType.length > 0) {
            url += 'filter[publicationType]=' + $scope.filters.publicationType.join(';') + '&';
          }

          if ($scope.filters.publisher.length > 0) {
            url += 'filter[publisher]=' + $scope.filters.publisher.join(';') + '&';
          }
        }

        if ($scope.filters.categories.length > 0) {
          url += 'filter[categories]=' + $scope.filters.categories.join(';') + '&';
        }

        if ($scope.filters.countries.length > 0) {
          url += 'filter[countries]=' + $scope.filters.countries.join(';') + '&';
        }

        url += 'filter[resourceTypes]=' + $scope.filters.resourceTypes.join(';') + '&';
        url += 'filter[themes]=' + $scope.filters.themes.join(';') + '&';
        url += 'p=' + ($scope.page - 1) + '&';
        url += 's=' + $scope.pageSize + '&';
        url += 'l=en&';

        bsfData.search(url, function(data) {
          if (data && data.hits) {
            $scope.resultMsg = $scope.searchQuery;
            $scope.searchError = false;
            $scope.searchResults = data.hits.hits;
            $scope.totalResults = data.hits.total;

            $scope.searchFilters.authors = window._.get(data, 'aggregations.filtered_authors.buckets', []);
            $scope.searchFilters.publicationType = window._.get(
              data,
              'aggregations.filtered_publication_types.buckets',
              [],
            );
            $scope.searchFilters.publisher = window._.get(
              data,
              'aggregations.filtered_publishers.buckets',
              [],
            );
            $scope.searchFilters.countries = window._.get(
              data,
              'aggregations.filtered_countries.buckets',
              [],
            );
            $scope.searchFilters.categories = window._.get(
              data,
              'aggregations.filtered_categories.buckets',
              [],
            );
          } else {
            $scope.searchError = true;
            $scope.errorMessage =
              'Error: could not retrieve search results at this time. If you continue having issues please contact us at support@cawst.org.';
          }
          $scope.loadingResults = false;
        }), // TODO: Remove comma expression
          function() {
            $scope.searchError = true;
            $scope.errorMessage =
              'Error: could not retrieve search results at this time. If you continue having issues please contact us at support@cawst.org.';
          };
      };
      ////////////////////////////////////////////////////////////////////////
      $scope.updateStateAndSearch = function(page, reload) {
        reload = reload || false;
        var options = {
          //options that all search views use
          query: $scope.searchQuery || '',
          page: page,
          countries:
            $scope.filters.countries && $scope.filters.countries.length > 0
              ? $scope.filters.countries.join(';')
              : undefined,
          categories:
            $scope.filters.categories && $scope.filters.categories.length > 0
              ? $scope.filters.categories.join(';')
              : undefined,
        };

        if ($scope.currentState === 'search') {
          //search specific filters
          options.resourceTypes = $scope.filters.resourceTypes.join(';');
          options.themes = $scope.filters.themes.join(';');
        }

        if ($scope.currentState === 'research') {
          //research specific filters
          options.publicationYearFrom = $scope.filters.publicationYearFrom;
          options.publicationYearTo = $scope.filters.publicationYearTo;
          options.authors =
            $scope.filters.authors && $scope.filters.authors.length > 0
              ? $scope.filters.authors.join(';')
              : undefined;
          options.publicationType =
            $scope.filters.publicationType && $scope.filters.publicationType.length > 0
              ? $scope.filters.publicationType.join(';')
              : undefined;
          options.publisher =
            $scope.filters.publisher && $scope.filters.publisher.length > 0
              ? $scope.filters.publisher.join(';')
              : undefined;
        }

        if ($scope.currentState === 'casestudies') {
          //case study specific filters
          options.organizations = $scope.filters.organizations.join(';');
        }

        $state.go($scope.currentState, options, { reload: reload });
        $scope.search();
      };
      ////////////////////////////////////////////////////////////////////////
      $scope.isSelected = function(filter, value) {
        return $scope.filters[filter].indexOf(value) !== -1;
      };
      ////////////////////////////////////////////////////////////////////////
      $scope.onFilterChanged = function(filter, value) {
        if ($scope.isSelected(filter, value)) {
          window._.remove($scope.filters[filter], function(item) {
            return item === value;
          });
        } else {
          $scope.filters[filter].push(value);
        }
        $scope.updateStateAndSearch();
      };
      ////////////////////////////////////////////////////////////////////////
      $scope.clearThemeSearchFilters = function() {
        var themeFilters = ['casestudy', 'research', 'faq'];
        var tempFilter = null;
        for (var filter in $scope.filters) {
          if (themeFilters.indexOf($scope.filters[filter][0]) !== -1) {
            tempFilter = $scope.filters[filter];
          }
          $scope.filters[filter] = [];
        }
        $scope.filters.resourceTypes = tempFilter;
        $scope.updateStateAndSearch();
      };
      ////////////////////////////////////////////////////////////////////////
      $scope.clearSearchFilters = function() {
        for (var filter in $scope.filters) {
          $scope.filters[filter] = [];
        }
        $scope.updateStateAndSearch();
      };
      ////////////////////////////////////////////////////////////////////////
      $scope.isFiltered = function() {
        var filtered = false;
        for (var filter in $scope.filters) {
          if ($scope.filters[filter] && $scope.filters[filter].length > 0) {
            filtered = true;
          }
        }
        return filtered;
      };
      ////////////////////////////////////////////////////////////////////////
      $scope.isThemeFiltered = function() {
        var filtered = false;
        for (var filter in $scope.filters) {
          if (filter !== 'resourceTypes') {
            if ($scope.filters[filter].length > 0) {
              filtered = true;
            }
          }
        }
        return filtered;
      };
      ////////////////////////////////////////////////////////////////////////
      $scope.getResourceIcon = function(resourceType) {
        var contentIcons = bsfUiUtil.contentIcons;
        return contentIcons[resourceType];
      };
      ////////////////////////////////////////////////////////////////////////
      init();
      ////////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: $scope.searchPageInfo.searchTitle,
        ogDescription: 'Search the Biosand Filters Knowledge Base',
        ogUrl: '/' + $scope.searchPageInfo.currentUrl,
      });
      ////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
