// /* tslint:disable:prefer-arrow-functions */

import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

interface IntercomOptionsData {
  app_id?: string;
  country?: string;
  email?: string;
  language_override?: string;
  name?: string;
  organization?: string;
  role_In_Organization?: string;
  user_id?: string;
  user_hash?: string;
}

const intercomDefaultOptions = {
  type: 'visitor',
  // email: 'anonymous@donotreply.org',
  // NOTE: When email: 'anonymous@donotreply.org' is included Intercom boots up
  // with "Hi Anonymous".
};

angular
  .module('app.services') //
  .service('intercomService', [
    '$cookies',
    '$window',
    'store',
    // tslint:disable-next-line:only-arrow-functions
    function($cookies, $window) {
      //
      function addDefaultOptions(options: IntercomOptionsData) {
        options.app_id = deploymentConfig.intercom.appId;
        options.language_override = $cookies.get('l');
        return options;
      }

      function bootWithAnonymous() {
        $window.Intercom('boot', addDefaultOptions(intercomDefaultOptions));
      }

      // Boot with user data, if enough info is available. Return false if anonymous user
      // should be used instead.
      function bootWithUser(profile): boolean {
        if (profile && profile.email) {
          const intercomOptions: IntercomOptionsData = {
            type: 'user',
            name: profile.name || [profile.firstName, profile.lastName].join(' '),
            email: profile.email,
            organization: profile.organization || null,
            country: profile.countryName || null,
            role_In_Organization: profile.organizationRole || null,
            user_id: profile.userId || null,
            user_hash: profile.userHash || null,
          };
          $window.Intercom('boot', addDefaultOptions(intercomOptions));
          return true;
        }
        return false;
      }

      function shutdown() {
        $window.Intercom('shutdown');
      }

      return {
        bootWithAnonymous,
        bootWithUser,
        shutdown,
      };
    },
  ]);
