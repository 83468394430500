(function() {
  angular.module('app.services').service('bsfData', [
    '$http',
    '$state',
    function($http, $state) {
      // /////////////////////////////////////////////////////////////////////
      function errorCallback(err, response) {
        if (response && response.data) {
          // TODO: Resolve unused cb
          // cb('Error:', response);
        } else if (err) {
          console.log(err);
        } else {
          console.log('no response');
        }
      }
      // /////////////////////////////////////////////////////////////////////
      // BSF prodtech id: 074f5f20-1070-40c7-b581-d8e9935e85fc
      // TODO: Resolve unused variable
      // const bsfProdTechId = '074f5f20-1070-40c7-b581-d8e9935e85fc';
      // /////////////////////////////////////////////////////////////////////
      this.search = function(url, cb) {
        $http.get(url).then(
          function(response) {
            if (response && response.data) {
              cb(response.data);
            } else {
              errorCallback();
              console.log('search');
            }
          },
          function(err) {
            errorCallback(err);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getSearchFilters = function(cb) {
        $http.get(window.appSettings.urls.hwtsSiteApiBaseUrl + '/public/search/bsf-filters').then(
          function(response) {
            if (response && response.data) {
              cb(response.data);
            } else {
              errorCallback();
              console.log('getSearchFilters');
            }
          },
          function(err) {
            errorCallback(err);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getDetails = function(type, id, cb) {
        $http.get(window.appSettings.urls.hwtsSiteApiBaseUrl + '/public/' + type + '/' + id).then(
          function(response) {
            if (response && response.data) {
              cb(response.data);
            } else {
              errorCallback();
              console.log('getDetails');
            }
          },
          function(err) {
            errorCallback(err);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getTechnicalDesignUpdates = function(type, limit, cb) {
        $http
          .get(window.appSettings.api.appUrl + '/public/themes/technical_design_updates/' + type + '/' + limit)
          .then(
            function(response) {
              if (response && response.data) {
                cb(response.data);
              } else {
                errorCallback();
                console.log('getTechnicalDesignUpdates');
              }
            },
            function(err) {
              errorCallback(err);
            },
          );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getImplementationApproaches = function(type, limit, cb) {
        $http.get(window.appSettings.api.appUrl + '/public/themes/approaches/' + type + '/' + limit).then(
          function(response) {
            if (response && response.data) {
              cb(response.data);
            } else {
              errorCallback();
              console.log('getImplementationApproaches');
            }
          },
          function(err) {
            errorCallback(err);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getCountryList = function(type, cb) {
        var url = window.appSettings.urls.hwtsSiteApiBaseUrl + '/public/' + type;
        if (type === 'country-focus-all') {
          url = window.appSettings.api.appUrl + '/public/' + type;
        }
        $http.get(url).then(
          function(response) {
            if (response && response.data) {
              cb(response.data);
            } else {
              errorCallback();
              console.log('getCountryList');
            }
          },
          function(err) {
            errorCallback(err);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getCountryDetails = function(id, cb) {
        $http.get(window.appSettings.urls.hwtsSiteApiBaseUrl + '/public/country-focus/' + id).then(
          function(response) {
            if (response && response.data) {
              cb(response.data);
            } else {
              errorCallback();
              console.log('getCountryDetails');
            }
          },
          function(err) {
            errorCallback(err);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getCountryAssociations = function(countryCode, contentType, cb) {
        $http.get(window.appSettings.api.appUrl + '/public/country-focus/' + countryCode + '/' + contentType).then(
          function(response) {
            if (response && response.data) {
              cb(response.data);
            } else {
              errorCallback();
              console.log('getCountryAssociations');
            }
          },
          function(err) {
            errorCallback(err);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getProducerDetails = function(id, cb) {
        $http.get(window.appSettings.urls.hwtsSiteApiBaseUrl + '/public/bsfproducer/' + id).then(
          function(response) {
            if (response && response.data) {
              cb(response.data);
            } else {
              errorCallback();
              console.log('getProducerDetails');
            }
          },
          function(err) {
            errorCallback(err);
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getBlogItems = function(cb) {
        $http
          .get('./api/blogdata/posts?filter[post_status]=publish&filter[category_name]=bsfkb')
          .then(
            function(response) {
              if (response && response.data) {
                cb(response.data);
              } else {
                errorCallback();
                console.log('getBlogItems');
              }
            },
            function(err) {
              errorCallback(err);
            },
          );
      };
      // /////////////////////////////////////////////////////////////////////
      this.getBlogBySlug = function(slug, cb) {
        $http
          .get(
            `./api/blogdata/posts?filter[post_status]=publish&filter[category_name]=bsfkb&filter[name]=${slug}`,
          )
          .then(
            function(response) {
              if (response && response.data.length > 0) {
                cb(response.data);
              } else {
                errorCallback();
                console.log('getBlogBySlug');
                $state.go('404');
              }
            },
            function(err) {
              errorCallback(err);
            },
          );
      };
      // /////////////////////////////////////////////////////////////////////
      this.topicPageList = [
        {
          title: 'Sand',
          slug: 'sand',
          excerpt:
            'The heart of the biosand filter is the sand. This is where the actual treatment processes occur. It is worth taking time and getting the sand right to ensure that your filter performs well.',
          img_src: 'images/topic-feature/sand.jpg',
        },
        {
          title: 'Quality Control',
          slug: 'quality-control',
          excerpt:
            'One of the biosand filter’s greatest advantages - local production - is also the source of one of its greatest challenges – inconsistent quality.',
          img_src: 'images/topic-feature/qc.jpg',
        },
        {
          title: 'Research Priorities',
          slug: 'research-priorities',
          excerpt:
            'There are still important unanswered questions about the biosand filter. Research on certain topics could make a real difference right away.',
          img_src: 'images/topic-feature/research-priorities.jpg',
        },
        {
          title: 'Monitoring & Evaluation for Improvement',
          slug: 'monitoring-evaluation',
          excerpt:
            'Monitoring helps us to answer the first question, while evaluation helps us to answer the second. Monitoring and evaluation are crucial components of effective WASH programs.',
          img_src: 'images/topic-feature/monitoring-evaluation.jpg',
        },
        {
          title: 'Mold',
          slug: 'mold',
          excerpt:
            'The mold is used to cast the BSF concrete boxes. They should be carefully made according to the specifications, because the quality of the concrete boxes depends on the high quality of the mold.',
          img_src: 'images/topic-feature/monitoring-evaluation.jpg',
        },
      ];
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
