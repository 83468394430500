/* eslint-disable prefer-destructuring */

(function() {
  angular.module('app.services').service('FormUtil', [
    '$http',
    function($http) {
      // /////////////////////////////////////////////////////////////////////////
      function addProducer(newProducer, cb) {
        var promise = $http.post(
          window.appSettings.urls.hwtsSiteApiBaseUrl + '/protected/bsfproducer',
          newProducer,
        );
        function onSuccess(response) {
          if (response) cb(true);
          else cb(false);
        }
        function onFailure(_error) {
          cb(false);
        }
        promise.then(onSuccess, onFailure);
      }
      // /////////////////////////////////////////////////////////////////////////
      function reviewProducer(newReview, cb) {
        var promise = $http.post(
          window.appSettings.urls.hwtsSiteApiBaseUrl + '/protected/bsfproducer/rating',
          newReview,
        );
        function onSuccess(response) {
          if (response) cb(true);
          else cb(false);
        }
        function onFailure(_error) {
          cb(false);
        }
        promise.then(onSuccess, onFailure);
      }
      // /////////////////////////////////////////////////////////////////////////
      function addExperience(newExperience, cb) {
        var promise = $http.post(
          window.appSettings.urls.hwtsSiteApiBaseUrl + '/protected/experience',
          newExperience,
        );
        function onSuccess(response) {
          if (response) cb(true);
          else cb(false);
        }
        function onFailure(_error) {
          cb(false);
        }
        promise.then(onSuccess, onFailure);
      }
      // /////////////////////////////////////////////////////////////////////////
      function addProject(newProject, cb) {
        var promise = $http.post(
          window.appSettings.urls.hwtsSiteApiBaseUrl + '/protected/project',
          newProject,
        );
        function onSuccess(response) {
          if (response) cb(true);
          else cb(false);
        }
        function onFailure(_error) {
          cb(false);
        }
        promise.then(onSuccess, onFailure);
      }
      // /////////////////////////////////////////////////////////////////////////
      function claimProject(input, cb) {
        var messageDetails = window._.cloneDeep(input);
        messageDetails.name = messageDetails.contacts[0].fullName;
        messageDetails.email = messageDetails.contacts[0].email;
        messageDetails.organization = messageDetails.contacts[0].organization;
        const promise = $http.post(
          window.appSettings.urls.hwtsSiteApiBaseUrl + '/protected/project/claim',
          messageDetails,
        );
        function onSuccess(response) {
          if (response) cb(true);
          else cb(false);
        }
        function onFailure(_error) {
          cb(false);
        }
        promise.then(onSuccess, onFailure);
      }
      // /////////////////////////////////////////////////////////////////////////
      function getOrganizationList(value) {
        return $http
          .get(
            window.appSettings.urls.hwtsSiteApiBaseUrl +
              '/public/lookup/organization?keywords=' +
              value,
          )
          .then(function(response) {
            return response;
          });
      }
      // /////////////////////////////////////////////////////////////////////////
      function getLocation(searchVal) {
        return $http
          .get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
              address: searchVal,
              sensor: false,
            },
            skipAuthorization: true,
          })
          .then(function(response) {
            const results = response.data.results;
            const ret = [];
            for (const i in results) {
              const newItem = {};
              for (const j in results[i].address_components) {
                const comp = results[i].address_components[j];
                if (comp.types && comp.types.indexOf('country') !== -1) {
                  newItem.countryCode = comp.short_name;
                  newItem.countryName = comp.long_name;
                  break;
                }
              }
              newItem.displayName = results[i].formatted_address;
              newItem.geolocation = {
                latitude: results[i].geometry.location.lat,
                longitude: results[i].geometry.location.lng,
              };
              newItem.raw = results[i];
              const numbers = searchVal.split(',');
              if (
                numbers.length === 2 &&
                isNaN(parseInt(numbers[0])) == false &&
                isNaN(parseInt(numbers[0])) == false
              )
                newItem.displayName = '(' + searchVal + ') ' + results[i].formatted_address;
              ret.push(newItem);
            }
            return ret;
          });
      }
      // /////////////////////////////////////////////////////////////////////////
      return {
        addProducer: addProducer,
        reviewProducer: reviewProducer,
        addExperience: addExperience,
        addProject: addProject,
        claimProject: claimProject,
        getOrganizationList: getOrganizationList,
        getLocation: getLocation,
      };
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
