/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
(() => {
  const app = angular.module('app');
  // /////////////////////////////////////////////////////////////////////////////
  // # Run
  // /////////////////////////////////////////////////////////////////////////////
  app.run([
    '$location',
    '$rootScope',
    '$state',
    '$window',
    'MetaTagsUtil',
    function($location, $rootScope, $state, $window, MetaTagsUtil) {
      // ///////////////////////////////////////////////////////////////////////////
      // rootscope variables
      $rootScope.api_url = $window.appSettings.api.appUrl;
      $rootScope.hwts_api_url = $window.appSettings.urls.hwtsSiteApiBaseUrl;
      $rootScope.hwts_url = $window.appSettings.urls.hwtsSite;
      $rootScope.env = $window.env;
      $rootScope.auth0_client_id = $window.appSettings.auth0.clientId;
      $rootScope.auth0_domain = $window.appSettings.auth0.domain;
      $rootScope.image_serve_url = $window.appSettings.resources.imageBaseUrl;
      $rootScope.appSettings = $window.appSettings;
      window.states = $state.get();
      // ///////////////////////////////////////////////////////////////////////////
      // # State Change Success
      $rootScope.$on('$stateChangeSuccess', function(
        _event,
        toState,
        _toParams,
        _fromState,
        _fromParams,
      ) {
        $window.Intercom('update');
        $window.scrollTo(0, 0);
        // /////////////////////////////////////////////////////////////////////////
        // setting MetaTagsUtil on state change
        MetaTagsUtil.stateChangedMetaTags();
        // ///////////////////////////////////////////////////////////////////////
        // Google Analytics
        gtag('config', window.appSettings.google.analyticsId, {
          page_path: $location.url(),
        });
      });
      // ///////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
