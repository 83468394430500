/* tslint:disable:no-var-requires */

// Don't use path aliases here! This file is processed by Webpack, which defines the aliases
// but (apparently) doesn't apply them to its own imports.

const { projectKey } = require('../../../package.json');
const defaultConfigRules = require('../../submodules/cawst-core/config/rules/rules')(projectKey);
const { deploymentTargets, projects } = require('../../submodules/cawst-core/config/projects');
import { ConfigMerger, ConfigRule } from '../../submodules/cawst-core/config/ConfigMerger';

const projectConfigRules: ConfigRule[] = [
  [
    'universal',
    {
      google: {
        analyticsId: 'UA-12911484-5',
      },
      featureFlags: {
        purgeCSS: true,
        useUniversalLogin: true,
      },
    },
  ],
  [
    'prod',
    {
      resources: {
        hwtsImageBaseUrl: projects['hwts-site'].assetBaseUrls.prod,
      },
    },
  ],
  [
    ['dev', 'local'],
    {
      resources: {
        hwtsImageBaseUrl: projects['hwts-site'].assetBaseUrls.dev,
      },
    },
  ],
];

// Add URLs of projects referenced in this project
function addProjectUrls(rules: ConfigRule[]) {
  for (const dt of deploymentTargets) {
    rules.push([
      dt,
      {
        urls: {
          hwtsSite: projects['hwts-site'].deploymentTargetOrigins[dt],
          hwtsSiteApiBaseUrl:
            projects['vwet-api'].deploymentTargetOrigins[dt] +
            projects['hwts-site'].apiBaseRoute,
          webManagerSite: projects['web-manager-site'].deploymentTargetOrigins[dt],
        },
      },
    ]);
  }

  rules.push([
    'prod',
    {
      urls: {
        hwtsImageBaseUrl: projects['hwts-site'].assetBaseUrls.prod,
      },
    },
  ]);

  rules.push([
    ['dev', 'local'],
    {
      urls: {
        hwtsImageBaseUrl: projects['hwts-site'].assetBaseUrls.dev,
      },
    },
  ]);
}

addProjectUrls(projectConfigRules);

const cm = new ConfigMerger({
  rules: [...defaultConfigRules, ...projectConfigRules],
});

const deploymentConfig = cm.getResolvedConfig();
export { deploymentConfig, projectConfigRules };
