/* eslint-disable no-param-reassign */

(function() {
  angular.module('app').controller('ProjectDetailCtrl', [
    '$location',
    '$rootScope',
    '$scope',
    '$state',
    'GoogleMapUtil',
    'MetaTagsUtil',
    function($location, $rootScope, $scope, $state, GoogleMapUtil, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////////
      $scope.loadingProjects = true;
      $scope.mapSettings = GoogleMapUtil.getMapSettings();
      const projectUrl = $location.$$url;
      // //////////////////////////////////////////////////////////////////////////
      GoogleMapUtil.getProject($state.params.projectID, function(project) {
        $scope.loadingProjects = false;
        if (project) {
          $scope.projectDetails = project;
          $rootScope.projectTitle = project.title; // used for breadcrumb
          $scope.mapSettings.map.zoom = 6;
          $scope.mapSettings.map.center = {
            latitude: project.latitude,
            longitude: project.longitude,
          };
          MetaTagsUtil.SetSocialMetaTags({
            ogTitle: $rootScope.projectTitle,
            ogDescription:
              'View and claim the Biosand Filter project, ' +
              $rootScope.projectTitle +
              ', implemented in ' +
              $scope.projectDetails.countries,
            ogUrl: projectUrl,
            ogImage: 'images/social/bsf_social_types_project.jpg',
          });
        }
      });
      // //////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
