/* eslint-disable no-param-reassign */

(function() {
  angular.module('app.directives').directive('listSections', [
    'GoogleMapUtil',
    function(GoogleMapUtil) {
      // /////////////////////////////////////////////////////////////////////////
      var sectionsWithoutListLimit = [
        'government',
        'keyitems',
        'project',
        'mold_producer',
        'sand_producer',
      ];
      // /////////////////////////////////////////////////////////////////////////
      function setListLimit(sectionId, totalItemsShowing) {
        var limit = 3;
        if (sectionsWithoutListLimit.indexOf(sectionId) > -1) limit = 10;
        else if (totalItemsShowing) limit = totalItemsShowing;
        return limit;
      }
      // /////////////////////////////////////////////////////////////////////////
      function setFilterByValue(filterByValue) {
        return filterByValue ? encodeURIComponent(filterByValue) : '';
      }
      // /////////////////////////////////////////////////////////////////////////
      function showViewAll(itemsLength, total, listLimit) {
        return total > listLimit || itemsLength > listLimit;
      }
      // /////////////////////////////////////////////////////////////////////////
      function setViewAllUrl(sectionId, filterBy, filterByValue) {
        var url = '/search?page=1';
        if (sectionId) {
          url += '&resourceTypes=' + sectionId;
        }
        if (filterBy === 'country') {
          url += '&countries=' + filterByValue;
        }
        // if (filterBy == 'themes') {
        // 	url += '&themes='+filterByValue;
        // }
        if (filterBy === 'categories') {
          url += '&categories=' + filterByValue;
        }
        return url;
      }
      // /////////////////////////////////////////////////////////////////////////
      function getItemType(sectionId) {
        const type = sectionId || '';
        return type;
      }
      // /////////////////////////////////////////////////////////////////////////
      return {
        restrict: 'E',
        templateUrl: 'scripts/directives/list_sections.html',
        scope: {
          section: '=',
          filterBy: '=',
          filterByValue: '=',
          totalItemsShowing: '=',
          mapCenter: '=',
          loadingProjects: '=', // use this or you will have errors if projects are not ready
        },
        link: function(scope, _element, _attr) {
          // TODO: Resolve unused variable
          const filterByValue = setFilterByValue(scope.filterByValue);

          scope.listLimit = setListLimit(scope.section.id, scope.totalItemsShowing);
          scope.viewAllUrl = setViewAllUrl(scope.section.id, scope.filterBy, scope.filterByValue);
          scope.showViewAll = showViewAll;
          scope.getItemType = getItemType;
          scope.sectionsWithoutListLimit = sectionsWithoutListLimit;

          // PROJECT MAP /////////////////////////////////////////////////////
          if (scope.section.id === 'project') {
            scope.showMap = false;
            if (!scope.loadingProjects && scope.section.items.length > 0) {
              scope.showMap = true;
            }
            scope.control = {};
            scope.mapSettings = GoogleMapUtil.getMapSettings();
            if (scope.filterBy === 'country') {
              // scope.mapSettings.map.center = scope.mapCenter;
              scope.mapSettings.map.zoom = 6;
            } else {
              scope.mapSettings.map.zoom = 2;
            }

            const latitudes = [];
            const longitudes = [];
            scope.section.items.forEach(function(project) {
              if (project.latitude) {
                latitudes.push(project.latitude);
              }
              if (project.longitude) {
                longitudes.push(project.longitude);
              }
            });
            scope.mapSettings.map.center = {
              latitude: window._.mean(latitudes),
              longitude: window._.mean(longitudes),
            };

            scope.mapSettings.mapEvents = {
              zoom_changed: function(map) {
                var zoomLevel = map.getZoom();
                if (zoomLevel >= 6) {
                  scope.mapSettings.typeOfMarker = 'spider';
                  scope.mapSettings.typeOptions = window._.clone(
                    scope.mapSettings.spiderfierOptions,
                  );
                } else {
                  scope.mapSettings.typeOfMarker = 'cluster';
                  scope.mapSettings.typeOptions = window._.clone(scope.mapSettings.clusterOptions);
                }
              },
            };
          }
        },
      };
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
