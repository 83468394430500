(function() {
  angular.module('app').controller('CountryListCtrl', [
    '$rootScope',
    '$scope',
    'bsfData',
    'bsfUiUtil',
    'MetaTagsUtil',
    function($rootScope, $scope, bsfData, bsfUiUtil, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////
      var listLimit = 42;
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList = {};
      $scope.countryList.featuredCountries = [];
      $scope.countryList.allCountries = [];
      $scope.countryList.query = '';
      $scope.countryList.limit = listLimit;
      // //////////////////////////////////////////////////////////////////////
      $scope.contactOpenIntercom = bsfUiUtil.contactOpenIntercom;
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.clearSearch = function() {
        $scope.countryList.query = '';
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.getFlagImageUrl = function(img) {
        return img ? $rootScope.image_serve_url + 'images/flags/' + img : '';
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.loadMore = function() {
        $scope.countryList.limit += listLimit;
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.loading = true;
      bsfData.getCountryList('country-focus', function(data) {
        $scope.countryList.featuredCountries = window._.toArray(data);
      });
      // //////////////////////////////////////////////////////////////////////
      bsfData.getCountryList('country-focus-all', function(data) {
        $scope.countryList.allCountries = window._.toArray(data);
        $scope.countryList.loading = false;
      });
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.showFeaturedCountries = function() {
        var show = true;
        if (
          $scope.countryList.loadingFeatured ||
          $scope.countryList.query.length > 0 ||
          $scope.countryList.featuredCountries.length <= 0
        ) {
          show = false;
        }
        return show;
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.showLoadMore = function() {
        var show = true;
        if (
          $scope.countryList.loading ||
          $scope.countryList.query.length > 0 ||
          $scope.countryList.allCountries.length <= $scope.countryList.limit
        ) {
          show = false;
        }
        return show;
      };
      // //////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'Country Focus',
        ogDescription: 'Producers, projects, experiences and research organized by country.',
        ogUrl: '/country-focus',
        ogImage: 'images/social/bsf_social_types_countryfocus.jpg',
      });
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
