/* tslint:disable:no-console */

import * as angular from 'angular';

import { toNgPost } from '@src/client/utilities/toNgPost';

angular //
  .module('app')
  .controller('UpdatesPageCtrl', [
    '$scope',
    'MetaTagsUtil',
    'StoryblokUtils',
    ($scope, MetaTagsUtil, StoryblokUtils) => {
      // //////////////////////////////////////////////////////////////////////////
      $scope.updatePosts = [];
      $scope.updatePosts.loading = true;
      // //////////////////////////////////////////////////////////////////////////
      StoryblokUtils.getBlogItems((data) => {
        $scope.updatePosts = data.stories.map((story) => toNgPost(story));
        $scope.updatePosts.loading = false;
      });
      // //////////////////////////////////////////////////////////////////////////
      $scope.getPostLink = (slug) => `/updates/${slug}`;
      // //////////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'BSF Recent Updates',
        ogDescription: 'Updates for biosand filter implementors.',
        ogUrl: '/updates',
        ogImage: 'images/social/bsf_social_types_updates.jpg',
      });
      // //////////////////////////////////////////////////////////////////////////
    },
  ]);
