/* tslint:disable:no-var-requires */
/* tslint:disable:ordered-imports */

const { deploymentConfig } = require('./deploymentConfig.ts');

window.appSettings = {};
window.appSettings = Object.assign({}, deploymentConfig);

// Entry
require('../scripts/app.js');

// Config block
require('../scripts/config');
require('../../submodules/cawst-angularjs/auth/auth.config');
require('../../submodules/cawst-angularjs/utils/google-maps.config');

// Run block
require('../scripts/run');

require('../scripts/services/services');
require('../../submodules/cawst-angularjs/user/user.service');
require('../scripts/services/bsf_data');
require('../scripts/services/bsf_ui');
require('../scripts/services/form_util');
require('../scripts/services/languageInterceptor');
require('../scripts/services/maps');
require('../scripts/services/metatags_util');
require('../scripts/services/storyblok-utils');
require('../scripts/services/sweet-alert-util');

require('../scripts/controllers/approaches');
require('../scripts/controllers/content');
require('../scripts/controllers/country/country-detail');
require('../scripts/controllers/country/country-list');
require('../scripts/controllers/error');
require('../scripts/controllers/forms');
require('../scripts/controllers/home');
require('../scripts/controllers/producer');
require('../scripts/controllers/projects/projects');
require('../scripts/controllers/projects/project-detail');
require('../scripts/controllers/researchers');
require('../scripts/controllers/search');
require('../scripts/controllers/topic');
require('../scripts/controllers/updates/updates');
require('../scripts/controllers/updates/update-detail');
require('../scripts/controllers/questionnaire');

require('../scripts/directives/directives');
require('../scripts/directives/edit_button');
require('../scripts/directives/header_search_inline');
require('../scripts/directives/list_item');
require('../scripts/directives/list_sections');
require('../scripts/directives/loading_bar');
require('../scripts/directives/progression_bar');
require('../scripts/directives/scroll_position');
require('../scripts/directives/side_menu');
require('../scripts/directives/star_rating');
require('../scripts/directives/viewall_button');

require('../scripts/filters/filters');

require('../scripts/route');

require('../../submodules/cawst-angularjs/auth/auth.imports');

require('../../submodules/cawst-angularjs/utils/intercom');

import '../fonts/cawst-icon-font/style.css';
import '../styles/main.scss';
