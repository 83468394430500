/* eslint-disable no-param-reassign */

(function() {
  angular.module('app').controller('ContentPageCtrl', [
    '$filter',
    '$location',
    '$rootScope',
    '$scope',
    '$state',
    'bsfData',
    'bsfUiUtil',
    'MetaTagsUtil',
    function($filter, $location, $rootScope, $scope, $state, bsfData, bsfUiUtil, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////////
      $scope.getPublicPdfDownload = bsfUiUtil.getPublicPdfDownload;
      $scope.getImageUrl = bsfUiUtil.getImageUrl;
      const contentId = $state.params.contentId || '';
      const contentSlug = $state.params.contentSlug || '';
      const contentType = $state.params.contentType || '';
      const contentUrl = $location.$$url;
      const parentStates = { research: 'research', 'case-study': 'casestudies', faq: 'faq' };
      $rootScope.parentState = parentStates[contentType] || null;
      // //////////////////////////////////////////////////////////////////////////
      $scope.contentPage = {};
      $scope.contentPage.loading = true;
      $scope.contentPage.contentType = contentType;
      $scope.contentPage.managerType =
        bsfUiUtil.managerTypeMap[$scope.contentPage.contentType] || $scope.contentPage.contentType;
      // //////////////////////////////////////////////////////////////////////////
      bsfData.getDetails(contentType, contentId, function(_details) {
        $scope.contentPage.details = _details;
        $scope.contentTypeLabel = bsfUiUtil.contentTypeLabelMap[contentType];
        $scope.contentTitleLabel = $scope.contentPage.details.title;
        $rootScope.crumbLabel = $scope.contentTitleLabel;
        if (!$scope.contentPage.details.publicationDate) {
          $scope.contentPage.publicationDetails = $scope.contentPage.details.publicationMonth
            ? $scope.contentPage.details.publicationMonth +
              '/' +
              $scope.contentPage.details.publicationYear
            : $scope.contentPage.details.publicationYear;
        } else {
          $scope.contentPage.publicationDetails = $filter('date')(
            $scope.contentPage.details.publicationDate,
            'MM/yyyy',
          );
        }
        // if (parentStates.hasOwnProperty(contentType)) {
        // 	$rootScope.crumbLabel = $scope.contentTitleLabel;
        // } else {
        // 	$rootScope.crumbLabel = $scope.contentTypeLabel;
        // }
        $scope.contentPage.loading = false;

        MetaTagsUtil.SetSocialMetaTags({
          ogTitle: $scope.contentTypeLabel + ': ' + $scope.contentTitleLabel,
          ogDescription: $scope.contentPage.details.description
            ? $scope.contentPage.details.description
            : null,
          ogUrl: contentUrl,
          ogImage: 'images/social/bsf_social_types_' + contentType + '.jpg',
        });
      });
    },
  ]);
})();
