/* eslint-disable no-param-reassign */

(function() {
  angular.module('app').controller('TopicPageCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    'bsfData',
    'MetaTagsUtil',
    function($rootScope, $scope, $state, bsfData, MetaTagsUtil) {
      // /////////////////////////////////////////////////////////////////////////
      // Topics are currently static, add them into bsf_data
      // name template view the same as slug
      // /////////////////////////////////////////////////////////////////////////
      $scope.currentTopic = {};
      $scope.currentTopic = window._.find(bsfData.topicPageList, { slug: $state.params.slug });
      // $scope.currentTopic.template = 'views/topics/' + $scope.currentTopic.slug + '.html';
      $rootScope.topicTitle = $scope.currentTopic.title;

      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'Topic: ' + $scope.currentTopic.title,
        ogDescription: $scope.currentTopic.excerpt,
        ogUrl: '/' + $scope.currentTopic.slug,
        ogImage: $scope.currentTopic.img_src,
      });
    },
  ]);
})();
