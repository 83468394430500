(() => {
  angular.module('app.routes', []).config([
    '$locationProvider',
    '$stateProvider',
    '$urlMatcherFactoryProvider',
    '$urlRouterProvider',
    function($locationProvider, $stateProvider, $urlMatcherFactoryProvider, $urlRouterProvider) {
      // /////////////////////////////////////////////////////////////////////////////
      $urlMatcherFactoryProvider.strictMode(false);
      $locationProvider.html5Mode(true).hashPrefix('!');

      // Return to the page from which the user logged in
      $urlRouterProvider.when('/callback', () => {
        // 'nextPathAfterLogin` is set when the user starts the login process.
        const nextPathAfterLogin =
          JSON.parse(window.localStorage.getItem('nextPathAfterLogin')) || '/';

        // `nextPathAfterLogin` is only needed once, so remove it from the store.
        window.localStorage.removeItem('nextPathAfterLogin');

        return nextPathAfterLogin;
      });

      $urlRouterProvider.otherwise('/404');
      // /////////////////////////////////////////////////////////////////////////////
      $stateProvider
        // /////////////////////////////////////////////////////////////////////////////
        .state('home', {
          url: '/',
          ncyBreadcrumb: {
            label: 'Home',
          },
          views: {
            '': {
              templateUrl: 'views/home.html',
              controller: 'HomePageCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('projects', {
          url: '/projects',
          ncyBreadcrumb: {
            label: 'Projects',
          },
          views: {
            '': {
              templateUrl: 'views/projects-map.html',
              controller: 'ProjectsCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
          },
        })
        .state('projects.detail', {
          url: '/:projectID/:projectSlug',
          ncyBreadcrumb: {
            label: '{{ projectTitle }}',
            parent: 'projects',
          },
          views: {
            '': {
              templateUrl: '/views/project.html',
              controller: 'ProjectDetailCtrl',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('topic', {
          url: '/topic/:slug',
          ncyBreadcrumb: {
            label: 'Topic: {{ topicTitle }}',
          },
          views: {
            '': {
              templateUrl: '/views/topic.html',
              controller: 'TopicPageCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('approaches', {
          url: '/implementation-approaches',
          ncyBreadcrumb: {
            label: 'Implementation Approaches',
          },
          views: {
            '': {
              templateUrl: 'views/approaches.html',
              controller: 'ApproachesCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('updates', {
          url: '/updates',
          ncyBreadcrumb: {
            label: 'Updates',
          },
          views: {
            '': {
              templateUrl: 'views/updates.html',
              controller: 'UpdatesPageCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('updates.detail', {
          url: '/:postSlug',
          ncyBreadcrumb: {
            label: '{{updatePostTitle}}',
          },
          views: {
            '': {
              templateUrl: 'views/update-detail.html',
              controller: 'UpdateDetailCtrl',
            },
          },
        })
        .state('search', {
          url: '/search?query&page&countries&resourceTypes&themes&categories',
          ncyBreadcrumb: {
            label: 'Search',
          },
          views: {
            '': {
              templateUrl: 'views/search.html',
              controller: 'SearchCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
          reloadOnSearch: false,
        })
        .state('researchers', {
          url: '/researchers',
          ncyBreadcrumb: {
            label: 'For Researchers',
          },
          views: {
            '': {
              templateUrl: '/views/researchers.html',
              controller: 'ResearchersPageCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('research', {
          url:
            '/research?query&page&countries&publicationYearFrom&publicationYearTo&authors&publicationType&publisher&categories',
          ncyBreadcrumb: {
            label: 'Research',
          },
          views: {
            '': {
              templateUrl: 'views/search.html',
              controller: 'SearchCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
          reloadOnSearch: false,
        })
        .state('faq', {
          url: '/faq?query&page&countries&categories',
          ncyBreadcrumb: {
            label: 'FAQ',
          },
          views: {
            '': {
              templateUrl: 'views/search.html',
              controller: 'SearchCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
          reloadOnSearch: false,
        })
        .state('casestudies', {
          url: '/case-studies?query&page&countries&organizations&categories',
          ncyBreadcrumb: {
            label: 'Case Studies',
          },
          views: {
            '': {
              templateUrl: 'views/search.html',
              controller: 'SearchCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
          reloadOnSearch: false,
        })
        .state('country', {
          url: '/country-focus',
          ncyBreadcrumb: {
            label: 'Country Focus',
          },
          views: {
            '': {
              templateUrl: '/views/country-list.html',
              controller: 'CountryListCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('country.detail', {
          url: '/:slugId/:slug',
          ncyBreadcrumb: {
            label: '{{ countryNameLabel }}',
          },
          templateUrl: '/views/country-detail.html',
          controller: 'CountryDetailCtrl',
        })
        .state('producer', {
          url: '/producer/:producerId/:producerSlug',
          ncyBreadcrumb: {
            label: 'Producer',
          },
          views: {
            '': {
              templateUrl: 'views/producer.html',
              controller: 'ProducerPageCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('content', {
          url: '/:contentType/:contentId/:contentSlug',
          ncyBreadcrumb: {
            label: '{{ crumbLabel }}',
            parent: function($scope) {
              return $scope.$parent.parentState;
            },
          },
          views: {
            '': {
              templateUrl: '/views/content.html',
              controller: 'ContentPageCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('form', {
          url: '/form/:form?pid&producerId&ptype&pcountry',
          views: {
            '': {
              templateUrl: '/views/forms.html',
              controller: 'FormsCtrl',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        })
        .state('signin', {
          url: '/signin',
          controller: 'AuthCtrl',
          templateUrl: '/views/signin.html',
        })
        // 404 error
        .state('404', {
          url: '/404',
          ncyBreadcrumb: {
            label: 'Not Found',
          },
          views: {
            '': {
              controller: '404ErrorCtrl',
              templateUrl: 'views/404.html',
            },
            'header@': {
              templateUrl: '/views/partials/_header.html',
            },
            'footer@': {
              templateUrl: '/views/partials/_footer.html',
            },
          },
        });
      // /////////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
