/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */

(function() {
  angular.module('app').controller('ProducerPageCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    'bsfData',
    'bsfUiUtil',
    'GoogleMapUtil',
    'MetaTagsUtil',
    function($rootScope, $scope, $state, bsfData, bsfUiUtil, GoogleMapUtil, MetaTagsUtil) {
      // /////////////////////////////////////////////////////////////////////////
      const producerId = $state.params.producerId;
      const producerSlug = $state.params.producerSlug;
      $scope.producerDetails = {};
      $scope.producerDetails.loading = true;
      $scope.mapSettings = GoogleMapUtil.getMapSettings();
      // /////////////////////////////////////////////////////////////////////////
      $scope.contactOpenIntercom = bsfUiUtil.contactOpenIntercom;
      // /////////////////////////////////////////////////////////////////////////
      bsfData.getProducerDetails(producerId, function(data) {
        $scope.producerDetails = data;
        $rootScope.producerLabel = $scope.producerDetails.title; // for breadcrumb

        $scope.producerDetails.map = {};
        $scope.producerDetails.map.latitude = GoogleMapUtil.getLat(data.locations);
        $scope.producerDetails.map.longitude = GoogleMapUtil.getLng(data.locations);
        $scope.mapSettings.map.zoom = 6;
        $scope.mapSettings.map.center = {
          latitude: $scope.producerDetails.map.latitude,
          longitude: $scope.producerDetails.map.longitude,
        };

        bsfData.getCountryList('country-focus-all', function(list) {
          $scope.returnCountry = window._.find(list, [
            'code',
            $scope.producerDetails.locations[0].countryCode,
          ]);
        });

        MetaTagsUtil.SetSocialMetaTags({
          ogTitle: $scope.producerDetails.title,
          ogDescription: $scope.producerDetails.description,
          ogUrl: '/producer' + producerId + '/' + producerSlug,
        });

        $scope.producerDetails.loading = false;
      });
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
