/* eslint-disable prefer-destructuring */

(function() {
  angular
    .module('app.filters', [])
    // /////////////////////////////////////////////////////////////////////////////
    .filter('htmlToPlaintext', function() {
      return function(text) {
        return text ? window.he.decode(String(text).replace(/<[^>]+>/gm, '')) : '';
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('contentTypeLabel', function(bsfUiUtil) {
      var contentTypeLabelMap = bsfUiUtil.contentTypeLabelMap;

      return function(contentType) {
        return contentTypeLabelMap[contentType] ? contentTypeLabelMap[contentType] : contentType;
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('contentTypeSlug', function(bsfUiUtil) {
      var map = bsfUiUtil.contentTypeSlugMap;
      return function(slug) {
        return map[slug] ? map[slug] : slug;
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('joinArray', function() {
      return function(array) {
        return window._.join(array, ', ');
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('trustAsHtml', function($sce) {
      return function(html) {
        return $sce.trustAsHtml(html);
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('niceCaseStudyDate', function() {
      return function(caseStudy) {
        var niceDate = '';
        if (caseStudy && caseStudy.startDate) {
          const startDate = window.moment(caseStudy.startDate);
          const endDate = caseStudy.endDate ? window.moment(caseStudy.endDate) : null;

          if (!endDate || startDate.format('M YYYY') === endDate.format('M YYYY'))
            niceDate = startDate.format('MMMM, YYYY');
          else if (startDate.format('YYYY') === endDate.format('YYYY'))
            niceDate = startDate.format('MMMM') + ' - ' + endDate.format('MMMM, YYYY');
          else niceDate = startDate.format('MMMM, YYYY') + ' - ' + endDate.format('MMMM, YYYY');
        }
        return niceDate;
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('displayLocationNames', function() {
      return function(array) {
        var ret = [];
        if (!array) {
          return null;
        }
        array.forEach(function(location) {
          if (location.countryName && location.countryName.length > 0) {
            ret.push(location.countryName);
          }
        });
        return ret.join(', ');
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('displayOrganizations', function() {
      return function(array, type) {
        if (!array) {
          return null;
        }
        const ret = [];
        array.forEach(function(org) {
          if (org.name) {
            ret.push(org.name);
          }
        });
        if (ret.length < 1 && type && type === 'experience') {
          ret[0] = 'Anonymous';
        }
        return ret.join(', ');
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('displayCountries', function() {
      return function(countries) {
        var display = countries;
        if (typeof countries !== 'string') display = window._.join(countries, ', ');
        return display;
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('displayFilename', function() {
      return function(file) {
        if (!file) {
          return null;
        }
        return file.title ? decodeURI(file.title) : decodeURI(file.name);
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('niceDocType', function(bsfUiUtil) {
      var docTypeMap = bsfUiUtil.contentTypeLabelMap;

      return function(docType) {
        return docTypeMap[docType] ? docTypeMap[docType] : 'Document';
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('filterSections', function() {
      return function(items) {
        var result = {};
        angular.forEach(items, function(value, key) {
          if (value.show) {
            result[key] = value;
          }
        });
        return result;
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('displayProducerAddress', function() {
      return function(array) {
        if (!array) {
          return null;
        }
        const ret = [];
        array.forEach(function(item) {
          if (item.address) {
            ret.push(item.address);
          }
          if (item.province) {
            ret.push(item.province);
          }
          if (item.city) {
            ret.push(item.city);
          }
          ret.push(item.displayName);
        });
        return ret.join(', ');
      };
    })
    // /////////////////////////////////////////////////////////////////////////////
    .filter('translate', function() {
      return function(str) {
        // return Translation.translate(str);
        return str;
      };
    });
  // /////////////////////////////////////////////////////////////////////////////
  // .filter('getParentRef', function($rootScope, $state) {
  //     return function(crumbState) {
  //         console.log(crumbState);
  //         if (crumbState == 'country.details' && $state.current.name == 'producer') {
  //             var slugId = $rootScope.returnCountry.slugId;
  //             var slug = $rootScope.returnCountry.slug;
  //             crumbState = 'country.details({slugId:slugId,slug:slug})';
  //             return crumbState
  //         } else {
  //             return crumbState;
  //         }
  // 	};
  // })
  // ///////////////////////////////////////////////////////////////////////////// // end filters
})();
