/* eslint-disable no-param-reassign */

(function() {
  angular.module('app.directives').directive('editButton', function() {
    // /////////////////////////////////////////////////////////////////////////
    function managerUrl(scope, _element, _attrs) {
      let managerSection = 'hwts/';
      if (scope.type === 'country') {
        managerSection = 'vwet/';
      }
      let url =
        window.appSettings.urls.webManagerSite +
        '/' +
        managerSection +
        scope.type +
        '/edit?id=' +
        scope.contentid;
      if (scope.alturl) {
        url = scope.alturl;
      }
      scope.managerUrl = url;
    }
    // /////////////////////////////////////////////////////////////////////////
    return {
      restrict: 'E',
      templateUrl: '/scripts/directives/edit_button.html',
      scope: {
        alturl: '@',
        type: '@',
        contentid: '@',
        message: '@',
      },
      link: managerUrl,
    };
  });
})();
