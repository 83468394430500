/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app').controller('FormsCtrl', [
    '$scope',
    '$state',
    'bsfUiUtil',
    'bsfData',
    'FormUtil',
    'GoogleMapUtil',
    'MetaTagsUtil',
    'SweetAlertUtil',
    'User',
    function(
      $scope,
      $state,
      bsfUiUtil,
      bsfData,
      FormUtil,
      GoogleMapUtil,
      MetaTagsUtil,
      SweetAlertUtil,
      User,
    ) {
      // //////////////////////////////////////////////////////////////////////////
      var formDetails = [
        {
          form: 'add-a-producer',
          title: 'Add a Producer',
          desc:
            'Submit a request to add a new producer to the Biosand Filters Knowledge Base. We will get back to you as soon as possible.',
          descriptionTitle: 'Short description',
          locationTitle: null,
          productTitle: null,
          titleField: 'Business name',
          titleRequired: true,
          descriptionPlaceholder: 'Tell us about this business in a few sentences',
          function: FormUtil.addProducer,
        },
        {
          form: 'review-a-producer',
          title: 'Submit a Review',
          desc:
            'Submit a request to review this business. We will get back to you as soon as possible. If you have any questions please contact us at support@cawst.org.',
          descriptionTitle: null,
          locationTitle: null,
          titleField: null,
          titleRequired: false,
          descriptionPlaceholder: null,
          function: FormUtil.reviewProducer,
        },
        {
          form: 'add-an-experience',
          title: 'Share Your Experience',
          desc:
            'Share your experience implementing a household water treatment and/or safe storage product or technology.',
          descriptionTitle: 'Summary of experience',
          locationTitle: 'Where does this experience apply?',
          titleField: null,
          titleRequired: false,
          descriptionPlaceholder: null,
          function: FormUtil.addExperience,
        },
        {
          form: 'add-a-project',
          title: 'Add a Project',
          desc:
            'Submit a request to add a project to the Biosand Filters Knowledge Base project map. We will get back to you as soon as possible. If you have any questions please contact us at support@cawst.org.',
          descriptionTitle: null,
          locationTitle: 'Where is your project located?',
          titleField: 'Project title',
          titleRequired: false,
          descriptionPlaceholder: 'Describe your project in a few sentences',
          function: FormUtil.addProject,
        },
        {
          form: 'claim-a-project',
          title: 'Claim this project',
          desc:
            'Did you or your organization implement this project? Submit a request to claim this project. We will get back to you as soon as possible. If you have any questions please contact us at support@cawst.org.',
          descriptionTitle: null,
          locationTitle: null,
          titleField: null,
          titleRequired: false,
          descriptionPlaceholder: null,
          function: FormUtil.claimProject,
        },
      ];
      // //////////////////////////////////////////////////////////////////////////
      function displayFormLabel(label) {
        var details = $scope.forms.pageDetails;
        var inputLabels = {
          title: details && details.title ? details.title : 'BSF Knowledge Base Form',
          desc:
            details && details.desc
              ? details.desc
              : 'Please complete all require fields. We will get back to you as soon as possible.',
          descriptionTitle:
            details && details.descriptionTitle ? details.descriptionTitle : 'Summary',
          locationTitle: details && details.locationTitle ? details.locationTitle : 'Location',
          titleField: details && details.titleField ? details.titleField : 'Title',
        };
        $scope.forms.descriptionPlaceholder =
          (details && details.descriptionPlaceholder) || 'Description';
        return inputLabels[label];
      }
      // //////////////////////////////////////////////////////////////////////////
      function isRequired(form, field) {
        var details = $scope.forms.pageDetails;
        var fields = {
          title: details && details.titleRequired ? details.titleRequired : false,
          name: details && details.titleRequired ? details.titleRequired : false,
          producerType: details && true,
          city: details && true,
          country: details && true,
        };
        return fields[field];
      }
      // //////////////////////////////////////////////////////////////////////////
      function displayUserName(userInfo) {
        var userName = '';
        if (userInfo) {
          if (userInfo.firstName) userName += userInfo.firstName;
          if (userInfo.firstName && userInfo.lastName) userName += ' ';
          if (userInfo.lastName) userName += userInfo.lastName;
        }
        return userName;
      }
      // //////////////////////////////////////////////////////////////////////////
      function getCountryList(cb) {
        bsfData.getCountryList('country-focus-all', function(countryList) {
          cb(countryList);
        });
      }
      // //////////////////////////////////////////////////////////////////////////
      function setProjectTitle(input) {
        var title = '';
        if (input && input.title) {
          title = input.title;
        }
        if (input && !input.title) {
          var orgName = window._.get(input, 'organizations[0].name', '');
          var country =
            window._.get(input, 'locations[0].countryName', null) !== null
              ? ' in ' + window._.get(input, 'locations[0].countryName')
              : '';
          title = orgName + country;
        }
        return title;
      }
      // //////////////////////////////////////////////////////////////////////////
      function sendRequest(form, input) {
        if (!input) {
          console.error('Input required to send request.');
        }
        if (!form) {
          console.error('Form info required to send request.');
        }
        function onSuccess() {
          $scope.forms.failure = false;
          SweetAlertUtil.alertPromise(
            'The request has been successfully submitted! A CAWST expert will get back to you as soon as possible.',
            'Success',
            'success',
          ).then(function() {
            bsfUiUtil.redirectBack();
          });
        }
        function onFailure() {
          SweetAlertUtil.alert(
            'The request could not be submitted, please try again. If the problem persists please contact CAWST at support@cawst.org.',
          );
          $scope.forms.failure = true;
        }
        var details = $scope.forms.pageDetails;
        if (details && details.function) {
          if (details.form === 'add-a-project') {
            input.title = setProjectTitle(input);
          }
          details.function(input, function(success) {
            if (success) onSuccess();
            else onFailure();
          });
        } else {
          onFailure();
        }
      }
      // //////////////////////////////////////////////////////////////////////////
      const profile = User.retrieveProfile();
      var projectId = $state.params.pid ? $state.params.pid : null;
      var producerType = $state.params.ptype || null;
      var producerId = $state.params.producerId || null;
      var producerCountry = $state.params.pcountry || null;
      // //////////////////////////////////////////////////////////////////////////
      $scope.forms = {};
      $scope.forms.pageDetails = window._.find(formDetails, { form: $state.params.form }) || null;
      $scope.forms.currentForm = $state.params.form ? $state.params.form : null;
      $scope.forms.displayFormLabel = displayFormLabel;
      $scope.forms.isRequired = isRequired;
      $scope.forms.getOrganizationList = FormUtil.getOrganizationList;
      $scope.forms.getLocation = FormUtil.getLocation;
      $scope.forms.input = {};

      if ($scope.forms.currentForm !== 'review-a-producer') {
        $scope.forms.input.prodTechs = [
          {
            id: '074f5f20-1070-40c7-b581-d8e9935e85fc',
            title: 'Biosand Filter',
          },
        ]; // default prodtech is BSF
        $scope.forms.input.contacts = [
          {
            email: profile && profile.email ? profile.email : '',
            fullName: displayUserName(profile),
            organization:
              profile && profile.organization && profile.organization.name
                ? profile.organization.name
                : '',
          },
        ];
      }

      if (
        $scope.forms.currentForm !== 'add-an-experience' &&
        $scope.forms.currentForm !== 'review-a-producer'
      ) {
        $scope.forms.input.websites = [
          {
            title: '',
            url: '',
          },
        ];
      }

      if ($scope.forms.currentForm === 'add-a-project') {
        $scope.forms.input.projectOngoing = true;
      }

      if (
        $scope.forms.currentForm === 'add-a-producer' ||
        $scope.forms.currentForm === 'review-a-producer'
      ) {
        $scope.forms.input.rating = $scope.forms.input.rating || 0;
      }

      if ($scope.forms.currentForm === 'add-a-producer') {
        $scope.forms.input.locations = [{}];
        $scope.forms.input.phoneNumbers = [];
        $scope.forms.input.emails = [];
        getCountryList(function(list) {
          $scope.forms.countryList = list;
        });
        $scope.bundleCountry = function() {
          $scope.forms.input.locations[0].countryCode = $scope.forms.countryOption.code;
          $scope.forms.input.locations[0].countryName = $scope.forms.countryOption.name;
          $scope.forms.input.locations[0].displayName = $scope.forms.countryOption.name;
        };
        if (producerCountry) {
          getCountryList(function(list) {
            var country = window._.find(list, ['name', producerCountry]);
            $scope.forms.countryOption = country;
            $scope.bundleCountry();
          });
        }
        if (producerType) {
          $scope.forms.input.producerType = producerType;
        }
      }
      // //////////////////////////////////////////////////////////////////////////
      if (producerId) {
        bsfData.getProducerDetails(producerId, function(data) {
          $scope.producerInfo = data;
          $scope.forms.input.id = $scope.producerInfo.id;
        });
      }
      // //////////////////////////////////////////////////////////////////////////
      if (projectId) {
        GoogleMapUtil.getProject(projectId, function(project) {
          if (project) {
            $scope.forms.input.projectId = project.id;
            $scope.forms.input.projectTitle = project.title;
            $scope.forms.input.projectCountry = project.locations[0].displayName;
            $scope.forms.input.projectDescription = project.description;
          }
        });
      }
      // //////////////////////////////////////////////////////////////////////////
      $scope.forms.datePicker = {};
      $scope.forms.datePicker.startDateOpen = false;
      $scope.forms.datePicker.endDateOpen = false;
      $scope.forms.datePicker.open = function(datePicker) {
        if (datePicker === 'start') $scope.forms.datePicker.startDateOpen = true;
        if (datePicker === 'end') $scope.forms.datePicker.endDateOpen = true;
      };
      // //////////////////////////////////////////////////////////////////////////
      $scope.forms.submit = function(isValid) {
        if (!isValid) {
          SweetAlertUtil.error('Please fill out all required form fields.');
          return;
        }
        if (!User.isSignedIn()) {
          User.signIn(
            function() {
              sendRequest($scope.forms.currentForm, $scope.forms.input);
            },
            function() {
              /* Error */
            },
          );
        } else {
          sendRequest($scope.forms.currentForm, $scope.forms.input);
        }
      };
      // //////////////////////////////////////////////////////////////////////////
      $scope.forms.cancel = function() {
        SweetAlertUtil.confirm(
          'Are you sure you want to cancel? If you do all data will be lost.',
        ).then(
          function(confirm) {
            if (confirm) bsfUiUtil.redirectBack();
          },
          function() {
            /* fail */
          },
        );
      };
      // //////////////////////////////////////////////////////////////////////////
      $scope.isSignedIn = function() {
        return User.isSignedIn();
      };
      // //////////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: displayFormLabel('title'),
        ogDescription: displayFormLabel('desc'),
        ogUrl: '/form/' + $scope.forms.currentForm,
      });
      // //////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
