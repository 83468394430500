/* tslint:disable:no-console */
import { bsfkbCategoryUuid, storyblokPublicToken } from '@src/client/scripts/_constants';
import * as angular from 'angular';

angular //
  .module('app.services')
  .service('StoryblokUtils', [
    '$http',
    '$state',
    function($http, $state) {
      this.getBlogItems = (cb) => {
        $http
          .get(
            `https://api.storyblok.com/v1/cdn/stories?starts_with=main-site/blog/posts&sort_by=first_published_at:desc&resolve_relations=author&filter_query[categories][in_array]=${bsfkbCategoryUuid}&token=${storyblokPublicToken}`,
          )
          .then((response, error) => {
            if (response && response.data) {
              cb(response.data);
            } else {
              console.log('Error:', error);
              console.log('getStoryblokBlogItems');
            }
          });
      };
      this.getBlogBySlug = (slug, cb) => {
        $http
          .get(
            `https://api.storyblok.com/v2/cdn/stories/main-site/blog/posts/${slug}?resolve_relations=author&token=${storyblokPublicToken}`,
          )
          .then(
            (response) => {
              if (response && response.data && response.data.story) {
                cb(response.data);
              } else {
                console.log('getBlogBySlug');
                $state.go('404');
              }
            },
            (err) => {
              console.log('Error:', err);
            },
          );
      };
    },
  ]);
