(function() {
  angular.module('app').controller('ProjectsCtrl', [
    '$scope',
    'GoogleMapUtil',
    'MetaTagsUtil',
    function($scope, GoogleMapUtil, MetaTagsUtil) {
      $scope.projects = [];
      $scope.mapSettings = GoogleMapUtil.getMapSettings();
      $scope.mapSettings.mapEvents = {
        zoom_changed: function(map) {
          var zoomLevel = map.getZoom();
          if (zoomLevel >= 6) {
            $scope.mapSettings.typeOfMarker = 'spider';
            $scope.mapSettings.typeOptions = window._.clone($scope.mapSettings.spiderfierOptions);
          } else {
            $scope.mapSettings.typeOfMarker = 'cluster';
            $scope.mapSettings.typeOptions = window._.clone($scope.mapSettings.clusterOptions);
          }
        },
      };
      $scope.projectsLoaded = false;

      $scope.mapSettings.map.center = {
        latitude: 1.51,
        longitude: -1.76,
      };
      $scope.mapSettings.map.zoom = 2;

      // //////////////////////////////////////////////////////////////////////////
      GoogleMapUtil.getProjects(function(projects) {
        if (projects) {
          $scope.projects = projects;
          $scope.projectsLoaded = true;
          $scope.$apply();
        }
      });
      // //////////////////////////////////////////////////////////////////////////
      $scope.showProjectOnMap = function(project) {
        $scope.mapSettings.map.center = {
          latitude: project.latitude,
          longitude: project.longitude,
        };
        $scope.mapSettings.map.zoom = 6;
        $scope.mapSettings.map.window.model = project;
        $scope.mapSettings.map.window.show = true;
      };
      // //////////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'Projects',
        ogDescription:
          "Who's doing what where. See what's happening in your country and add your own project.",
        ogUrl: '/projects',
        ogImage: 'images/social/bsf_social_types_projects.jpg',
      });
      // //////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
