(function() {
  angular.module('app').controller('HomePageCtrl', [
    '$scope',
    '$state',
    'bsfData',
    'MetaTagsUtil',
    function($scope, $state, bsfData, MetaTagsUtil) {
      $scope.topics = [];

      // get topic pages
      const topics = bsfData.topicPageList;
      topics.forEach(function(topic) {
        if (topic.slug !== 'research-priorities') {
          $scope.topics.push(topic);
        }
      });

      $scope.searchPageGo = function(query) {
        // can't get submit on enter to work without this function
        const searchQuery = query || '';
        $state.go('search', { query: searchQuery, page: 1 }, { reload: true });
      };

      MetaTagsUtil.SetSocialMetaTags({});
    },
  ]);
})();
