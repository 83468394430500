import { RichtextNode } from '@src/client/types/SbRichtextNode.ts';
import schema from 'storyblok-js-client/source/schema';

// Corrects extra <p> tags in lists https://github.com/storyblok/storyblok-js-client/issues/59#issuecomment-660135296
// eslint-disable-next-line camelcase
schema.nodes.list_item = (node: RichtextNode) => {
  if (node.content && node.content.length === 1 && node.content[0].type === 'paragraph') {
    const { content } = node.content[0];
    // eslint-disable-next-line no-param-reassign
    node.content = content;
  }
  return {
    tag: 'li',
  };
};

// Changes links to open in new tab
schema.marks.link = (node: RichtextNode) => {
  // Spread to avoid modifying `node`
  const attrs = { ...node.attrs };
  const { linktype = 'url' } = attrs;

  if (linktype === 'email') {
    attrs.href = `mailto:${attrs.href}`;
  }

  if (attrs.anchor) {
    attrs.href = `${attrs.href}#${attrs.anchor}`;
    delete attrs.anchor;
  }

  attrs.target = '_blank';
  attrs.rel = 'noopener';

  return {
    tag: [
      {
        tag: 'a',
        attrs,
      },
    ],
  };
};

export { schema as CustomSchema };
