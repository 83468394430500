/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */

(function() {
  angular.module('app').controller('ApproachesCtrl', [
    '$element',
    '$scope',
    '$uiViewScroll',
    'bsfData',
    'GoogleMapUtil',
    'MetaTagsUtil',
    function($element, $scope, $uiViewScroll, bsfData, GoogleMapUtil, MetaTagsUtil) {
      // @TODO: if more themes like this come up, we should use the list content directive. Currently using side menu directive
      // //////////////////////////////////////////////////////////////////////////
      $scope.approaches = {};
      $scope.approaches.title = 'Implementation Approaches';
      $scope.approaches.description = '';
      $scope.approaches.sectionLength = 3;
      $scope.approaches.sections = {
        'case-study': {
          id: 'casestudy',
          show: false,
          title: 'Case Studies',
          items: [],
        },
        experience: {
          id: 'experience',
          show: false,
          title: 'Experiences',
          items: [],
        },
        faq: {
          id: 'faq',
          show: false,
          title: 'FAQ',
          items: [],
        },
        research: {
          id: 'research',
          show: false,
          title: 'Research',
          items: [],
        },
        update: {
          id: 'update',
          show: false,
          title: 'Updates',
          items: [],
        },
        project: {
          id: 'project',
          show: false,
          title: 'Projects',
          items: [],
        },
      };
      // //////////////////////////////////////////////////////////////////////////
      $scope.approaches.scrollToSection = function(elementId) {
        $uiViewScroll($element.find('#' + elementId));
      };
      // //////////////////////////////////////////////////////////////////////////
      $scope.approaches.loadingContent = true;
      $scope.approaches.loadingProjects = true;
      // //////////////////////////////////////////////////////////////////////////
      bsfData.getImplementationApproaches('casestudy', $scope.approaches.sectionLength, function(
        data,
      ) {
        if (data.items.length > 0) {
          $scope.approaches.sections['case-study'].items = data.items;
          $scope.approaches.sections['case-study'].listTotal = data.total;
          $scope.approaches.sections['case-study'].show = true;
        }
      });

      bsfData.getImplementationApproaches('document', $scope.approaches.sectionLength, function(
        data,
      ) {
        if (data.items.length > 0) {
          data.items.forEach(function(item) {
            if (!item.type) {
              item.type = 'document';
            }
          });
          $scope.approaches.sections['update'].items = data.items;
          $scope.approaches.sections['update'].listTotal = data.total;
          $scope.approaches.sections['update'].show = true;
        }
      });

      bsfData.getImplementationApproaches('experience', $scope.approaches.sectionLength, function(
        data,
      ) {
        if (data.items.length > 0) {
          $scope.approaches.sections['experience'].items = data.items;
          $scope.approaches.sections['experience'].listTotal = data.total;
          $scope.approaches.sections['experience'].show = true;
        }
      });

      bsfData.getImplementationApproaches('faq', $scope.approaches.sectionLength, function(data) {
        if (data.items.length > 0) {
          $scope.approaches.sections['faq'].items = data.items;
          $scope.approaches.sections['faq'].listTotal = data.total;
          $scope.approaches.sections['faq'].show = true;
        }
      });

      bsfData.getImplementationApproaches('research', $scope.approaches.sectionLength, function(
        data,
      ) {
        if (data.items.length > 0) {
          $scope.approaches.sections['research'].items = data.items;
          $scope.approaches.sections['research'].listTotal = data.total;
          $scope.approaches.sections['research'].show = true;
        }
      });

      GoogleMapUtil.getProjects(function(projects) {
        var filteredProjects = projects.filter(function(project) {
          var themes = project.themes || [];
          var filteredThemes = themes.filter(function(theme) {
            return theme.slug === 'approaches';
          });
          return filteredThemes.length > 0;
        });
        $scope.approaches.sections['project'].items = filteredProjects;
        $scope.approaches.sections['project'].show = true;
        $scope.approaches.loadingProjects = false;
      });

      bsfData.getBlogItems(function(data) {
        data.forEach(function(post) {
          // only getting posts tagged with 'approaches'
          if (post.terms.post_tag) {
            post.terms.post_tag.forEach(function(tag) {
              if (tag.slug === 'approaches') {
                $scope.approaches.sections['update'].items.push(post);
                $scope.approaches.sections['update'].listTotal += 1;
                $scope.approaches.sections['update'].show = true;
              }
            });
          }
        });
        $scope.approaches.loadingContent = false;
      });
      // //////////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'Implementation Approaches',
        ogUrl: '/implementation-approaches',
      });
      // //////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
