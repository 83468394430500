/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-destructuring */

(function() {
  angular.module('app.services').service('MetaTagsUtil', [
    '$rootScope',
    '$state',
    '$timeout',
    '$window',
    function($rootScope, $state, $timeout, $window) {
      var website = 'https://www.biosandfilters.info';
      var imageServe = $rootScope.image_serve_url;

      var defaultTags = {
        title: 'Biosand Filters Knowledge Base',
        description:
          "A practitioner's knowledge base. A biosand filter (BSF) is an adaptation of the traditional slow sand filter, which has been used for community drinking water treatment for 200 years.",
        ogImage: imageServe + 'images/social/bsf_social_media.jpg',
        ogImageWidth: 500,
        ogImageHeight: 250,
        ogType: 'website',
        ogTitle: 'Biosand Filters Knowledge Base',
        ogDescription:
          "A practitioner's knowledge base. A biosand filter (BSF) is an adaptation of the traditional slow sand filter, which has been used for community drinking water treatment for 200 years.",
        ogUrl: website,
      };

      const SetTitle = function(cstmTags) {
        let title = defaultTags.title;
        if (cstmTags.title) {
          title = cstmTags.title;
        } else if (cstmTags.ogTitle) {
          title = cstmTags.ogTitle;
        }
        return title;
      };

      const SetDescription = function(cstmTags) {
        let description = defaultTags.description;
        if (cstmTags.description) {
          description = cstmTags.description;
        } else if (cstmTags.ogDescription) {
          description = cstmTags.ogDescription;
        }
        return description;
      };

      var PrerenderReady = function() {
        // from https://goo.gl/jCyHfA
        if (!$window.prerenderReady) {
          const unregister = $rootScope.$watch(function() {
            unregister();
            $timeout(
              function() {
                $window.prerenderReady = true;
              },
              0,
              false,
            );
          });
        }
      };

      var SetImageUrl = function(cstmTags) {
        var imageUrl = defaultTags.ogImage;
        if (cstmTags.ogImage) {
          imageUrl = imageServe + cstmTags.ogImage;
        }
        if (cstmTags.ogAltImage) {
          imageUrl = cstmTags.ogAltImage;
        }
        return imageUrl;
      };

      var statesList = {};

      return {
        // //////////////////////////////////////////////////////////////////////
        stateChangedMetaTags: function() {
          if (statesList.hasOwnProperty($state.current.name)) {
            $rootScope.metaTags = statesList[$state.current.name];
          }
        },

        SetSocialMetaTags: function(cstmTags, stateName) {
          if (cstmTags) {
            const normalizedTags = {
              title: SetTitle(cstmTags),
              description: SetDescription(cstmTags),
              ogImage: SetImageUrl(cstmTags),
              ogImageWidth: cstmTags.ogImageWidth
                ? cstmTags.ogImageWidth
                : defaultTags.ogImageWidth,
              ogImageHeight: cstmTags.ogImageHeight
                ? cstmTags.ogImageHeight
                : defaultTags.ogImageHeight,
              ogType: cstmTags.ogType ? cstmTags.ogType : defaultTags.ogType,
              ogTitle: cstmTags.ogTitle ? cstmTags.ogTitle : defaultTags.ogTitle,
              ogDescription: cstmTags.ogDescription
                ? cstmTags.ogDescription
                : defaultTags.ogDescription,
              ogUrl: cstmTags.ogUrl ? website + cstmTags.ogUrl : defaultTags.ogUrl,
            };

            if (!stateName) {
              stateName = $state.current.name;
            }

            if (stateName === $state.current.name) {
              $rootScope.metaTags = normalizedTags;
              PrerenderReady();
            }
            statesList[stateName] = normalizedTags;
          } else {
            $rootScope.metaTags = defaultTags;
            PrerenderReady();
          }
        },
        // //////////////////////////////////////////////////////////////////////
      };
    },
  ]);
})();
