(function() {
  angular.module('app.directives').directive('loadingbar', function() {
    return {
      restrict: 'E',
      templateUrl: '/scripts/directives/loading_bar.html',
      scope: {
        size: '@', // can accept lg, md, sm
        message: '@',
      },
    };
  });
})();
