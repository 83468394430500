/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */

(() => {
  const app = angular.module('app');
  // //////////////////////////////////////////////////////////////////////////////
  app.config([
    '$provide',
    function($provide) {
      $provide.decorator('$uiViewScroll', function() {
        return function(uiViewElement) {
          angular.element('html,body').animate(
            {
              scrollTop: uiViewElement.offset().top - 90,
            },
            500,
          );
        };
      });
    },
  ]);
  // //////////////////////////////////////////////////////////////////////////////
  app.config([
    '$httpProvider',
    function($httpProvider) {
      $httpProvider.interceptors.push('languageInterceptor');
    },
  ]);
  // //////////////////////////////////////////////////////////////////////////////
  app.config([
    '$breadcrumbProvider',
    function($breadcrumbProvider) {
      $breadcrumbProvider.setOptions({
        prefixStateName: 'home',
        templateUrl: 'views/partials/_breadcrumbs.html',
      });
    },
  ]);
})();
