/* eslint-disable no-param-reassign */

(function() {
  angular.module('app.directives').directive('scrollPosition', [
    '$window',
    function($window) {
      // # Scroll Position
      // Gets scroll position; used to change header class
      return {
        scope: {
          scroll: '=scrollPosition',
        },
        link: function(scope) {
          var windowEl = angular.element($window);
          var handler = function() {
            scope.scroll = windowEl.scrollTop();
          };
          windowEl.on('scroll', scope.$apply.bind(scope, handler));
          handler();
        },
      };
    },
  ]);
})();
