import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

import { AppUserProfileData } from '../_types';

angular //
  .module('app')
  .service('authService', [
    '$state',
    '$http',
    '$location',
    '$timeout',
    '$window',
    'angularAuth0',
    'store',
    // Don't use an arrow function here (or in any Angular constructor call)
    // tslint:disable-next-line:only-arrow-functions
    function($state, $http, $location, $timeout, $window, angularAuth0, store) {
      //
      // ==========
      // PRIVATE METHODS

      const requiredProfileVersion = 3;
      const onAuthenticationCallbacks = [];

      function clearSession() {
        // Remove tokens and expiry time from localStorage
        store.remove('access_token');
        store.remove('id_token');
        store.remove('expires_at');
        store.remove('profile');
        // .signOut() is called so often that it will remove the `nextPathAfterLogin' before it
        // can be used. See #HWTS-71
        // store.remove('nextPathAfterLogin');
      }

      // ----------
      // These details are needed for website forms (HWTS:src/client/scripts/controllers/forms.js):
      //   - firstName, lastName
      // These details needed by Intercom (src/client/cawst-angularjs-client/user/user.service.js):
      //   - name, organization.name, countryName, organizationRole, cawstUserId
      async function fetchProfileFromApi(): Promise<AppUserProfileData> {
        console.log('auth.service.ts | Fetching profile from API');
        const vwetUserUrl = `${deploymentConfig.api.origin}/user/profile`; // endpoint for v3 profile

        // The API allows this operation only if the access token is sent in a request header.
        // apiRequest.service automatically attaches the token to all calls to the VWET API.
        // prettier-ignore
        // FIXME: Error Handling, Testing
        const response = await $http.get(vwetUserUrl);
        return response.data;
      }

      // ----------
      // If multiple events are used, consider creating a standalone notification service
      function notifyAuthenticationSubscribers(eventName, data) {
        onAuthenticationCallbacks.forEach((cb) => {
          cb(data);
        });
      }

      // ----------
      function profileIsValid() {
        const profile = retrieveProfile();
        return !!(profile && profile.version === requiredProfileVersion);
      }

      // ----------
      function retrieveAccessToken() {
        return store.get('access_token') || '';
      }

      // ----------
      function storeSession(authResult) {
        // Reference: https://auth0.com/docs/tokens
        // ID Token: contains user profile attributes; in JWT format
        // Access Token: credential for accessing the Auth0 Management API

        // Set the time that the Access Token will expire at
        const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
        store.set('access_token', authResult.accessToken);
        store.set('id_token', authResult.idToken);
        store.set('expires_at', expiresAt);
        // console.log('access_token', authResult.accessToken);
      }

      // ----------
      function sessionIsValid() {
        return (
          !!retrieveAccessToken() &&
          !!store.get('expires_at') &&
          !!store.get('id_token') &&
          !tokenIsExpired() &&
          profileIsValid()
        );
      }

      function tokenIsExpired() {
        return new Date().getTime() > store.get('expires_at');
      }

      // ==========
      // PUBLIC METHODS

      // ----------
      // Retrieves the profile from local store if available, otherwise fetches it from the API
      async function retrieveOrFetchProfile(): Promise<AppUserProfileData> {
        // console.log('auth.service.ts | Retrieving profile asynchronously');
        const profile: AppUserProfileData = retrieveProfile();
        if (profileIsValid()) {
          console.log('auth.service.ts | Profile retrieved from store');
          return profile;
        }
        store.remove('profile');
        return fetchProfileFromApi().then((fetchedProfile) => {
          storeProfile(fetchedProfile);
          return fetchedProfile;
        });
      }

      // ----------
      // This is run whenever the website reloads
      function handleAuthentication() {
        // console.log('auth.service.ts | handleAuthentication();');
        angularAuth0.parseHash((err, authResult) => {
          // Parse tokens in the URL
          if (authResult && authResult.accessToken && authResult.idToken) {
            storeSession(authResult);

            retrieveOrFetchProfile()
              .then((profile) => {
                // TODO: Rename the event; `onRetrieveProfile` is misleading
                notifyAuthenticationSubscribers('onRetrieveProfile', {
                  isSignedIn: true,
                  profile,
                });
              })
              .catch((_err) => {
                notifyAuthenticationSubscribers('onRetrieveProfile', {
                  isSignedIn: false,
                  profile: null,
                });
                clearSession();
              });
          } else if (err) {
            console.error('An error occurred in auth.service.ts handleAuthentication():', err);
            $timeout(() => {
              // Execute in the next event loop
              $state.go('home');
            });
            // TODO: Handle the failure
            console.error('Authentication failed', err);
          } else {
            // Authentication tokens weren't provided in the URL hash, so this isn't a callback
            // from a login. Send the login status and profile to other components.
            if (sessionIsValid()) {
              notifyAuthenticationSubscribers('onRetrieveProfile', {
                isSignedIn: true,
                profile: store.get('profile'),
              });
            } else {
              clearSession();
              notifyAuthenticationSubscribers('onRetrieveProfile', {
                isSignedIn: false,
                profile: null,
              });
            }
          }
        });
      }

      // ----------
      function isAuthenticated(): boolean {
        // TOTEST: Note that the Auth0 sample does not check whether `expires_at` exists

        // Check whether the current time is past the Access Token's expiry time
        // console.log('isAuthenticated');
        return sessionIsValid();
      }

      // ----------
      // Deprecated
      function retrieveIdToken() {
        return store.get('id_token') || '';
      }

      // ----------
      function retrieveProfile(): AppUserProfileData {
        return store.get('profile');
      }

      // ----------
      function signIn() {
        // angularAuth0 calls {auth0Domain}/login with querystring parameters.
        // For example (URL codes are unescaped for readability):
        // https://cawst.auth0.com/login?
        //   state=44rDNroUmIqYsIFQaSu4wvKri7GtDWGy&
        //   client={VWET or VWET Dev Client ID}&
        //   protocol=oauth2&
        //   response_type=token id_token&
        //   redirect_uri=http://localhost:3006/callback&
        //   scope=openid&
        //   audience=https://cawst.auth0.com/userinfo&
        //   nonce=s1_u5U6X-kAjmyXKv7pJR8qOnnhb64G9&
        //   auth0Client=eyJuYW1lIjoiYXV0aDAuanMiLCJ2ZXJzaW9uIjoiOS42LjEifQ%3D%3D
        store.set('nextPathAfterLogin', $location.path());
        angularAuth0.authorize();
      }

      // ----------
      // TODO: This is called more often than necessary. It should be called only upon a detected
      // sign out, so that the session can be cleared and `$state.go('home')` can be issued.
      function signOut() {
        angularAuth0.logout({
          clientID: deploymentConfig.auth0.clientId,
          returnTo: deploymentConfig.origin, // Auth0 redirects to this page after logout
        });
        clearSession();
      }

      function storeProfile(profile: AppUserProfileData) {
        store.set('profile', profile);
      }

      // ----------
      // If multiple events are used, consider creating a standalone notification service
      function subscribeToAuthentication(cb) {
        onAuthenticationCallbacks.push(cb);
      }

      return {
        handleAuthentication,
        isAuthenticated,
        retrieveAccessToken,
        retrieveIdToken,
        retrieveProfile,
        signIn,
        signOut,
        storeProfile,
        subscribeToAuthentication,
      };
    },
  ]);
