(function() {
  angular.module('app.services').service('bsfUiUtil', [
    '$window',
    function($window) {
      // /////////////////////////////////////////////////////////////////////////
      this.contentTypeLabelMap = {
        casestudy: 'Case Study',
        casestudies: 'Case Studies',
        'case-study': 'Case Study',
        document: 'Document',
        evaluation: 'Evaluation',
        manufacturer_sheet: 'Manufacturer Sheet',
        policy_regulation: 'Policy or Regulation',
        manual: 'Manual',
        implementation_update: 'Implementation Update',
        technical_update: 'Technical Update',
        experience: 'Experience',
        faq: 'FAQ (Frequently Asked Question)',
        prodtech: 'Product or Technology',
        project: 'Project',
        research: 'Research',
        update: 'Update',
        website: 'Website',
        author: 'Author',
      };
      // /////////////////////////////////////////////////////////////////////////
      this.contentTypeSlugMap = {
        casestudy: 'case-study',
        manufacturer_sheet: 'document',
        policy_regulation: 'document',
        manual: 'document',
        implementation_update: 'document',
        technical_update: 'document',
        evaluation: 'document',
      };
      // /////////////////////////////////////////////////////////////////////////
      this.managerTypeMap = {
        'case-study': 'casestudy',
      };
      // /////////////////////////////////////////////////////////////////////////
      this.contentIcons = {
        casestudy: 'fa-pencil',
        document: 'fa-file-o',
        manufacturer_sheet: 'fa-file-o',
        policy_regulation: 'fa-file-o',
        manual: 'fa-file-o',
        implementation_update: 'fa-file-o',
        technical_update: 'fa-file-o',
        experience: 'fa-quote-left',
        faq: 'fa-question-circle',
        project: 'fa-map-marker',
        research: 'fa-graduation-cap',
      };
      // /////////////////////////////////////////////////////////////////////////
      this.stringIsEmail = function(string) {
        var regex = /(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/g;
        return regex.test(string);
      };
      // /////////////////////////////////////////////////////////////////////////
      this.redirectBack = function() {
        $window.history.back();
      };
      // /////////////////////////////////////////////////////////////////////////
      this.getPublicPdfDownload = function(uri) {
        $window.open(window.appSettings.urls.hwtsSiteApiBaseUrl + '/public/download?uri=' + uri);
      };
      // /////////////////////////////////////////////////////////////////////////
      this.getImageUrl = function(uri) {
        return window.appSettings.urls.hwtsImageBaseUrl + uri;
      };
      // /////////////////////////////////////////////////////////////////////////
      this.getContentUrl = function(contentType, id, title) {
        var slugId = id.substring(0, 8);
        var slug = window._.kebabCase(title);
        return {
          contentType: contentType,
          slugId: slugId,
          slug: slug,
        };
      };
      // /////////////////////////////////////////////////////////////////////////
      this.contactOpenIntercom = function(message) {
        if (message) {
          $window.Intercom('showNewMessage', message);
        } else {
          $window.Intercom('showNewMessage');
        }
      };
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
})();
