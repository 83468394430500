(function() {
  angular.module('app').controller('ResearchersPageCtrl', [
    '$scope',
    'MetaTagsUtil',
    function($scope, MetaTagsUtil) {
      $scope.$on('$viewContentLoaded', function() {
        var fileref = window.document.createElement('link');
        fileref.rel = 'stylesheet';
        fileref.type = 'text/css';
        fileref.href = 'assets/line-icons.css';
        window.document.getElementsByTagName('head')[0].appendChild(fileref);
      });

      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'Researchers',
        ogDescription:
          'You are interested in researching the biosand filter? Here is a great set of resources to start off your literature review!',
        ogUrl: '/researchers',
        ogImage: 'images/social/bsf_social_types_researchers.jpg',
      });
    },
  ]);
})();
