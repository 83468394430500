angular
  .module('app')
  // =============================================================================
  // # 404 CONTROLLER
  // =============================================================================
  .controller('404ErrorCtrl', [
    'MetaTagsUtil',
    '$state',
    (MetaTagsUtil, $state) => {
      setTimeout(() => {
        $state.go('home');
      }, 3000);

      MetaTagsUtil.SetSocialMetaTags({
        title: 'Oops, we could not find what you were looking for',
        ogTitle: 'Oops, we could not find what you were looking for',
        ogUrl: '/404',
      });
    },
  ]);
